import AlertError from "components/Alert/Error";
import { toTitleCase } from "utils/helpers";

type Props = {
  errors?: any;
};

const ERROR_KEY_LOOKUP: any = {
  newPassword: "Password",
  newPassword1: "Password",
  newPassword2: "Password",
};

export default function FormErrors({ errors }: Props) {
  if (errors) {
    let _errors: Array<string> = [];

    if (typeof errors === "string") {
      _errors = [errors];
    }

    if (Array.isArray(errors)) {
      _errors = [..._errors, ...errors];
    }

    if (typeof errors === "object" && !Array.isArray(errors)) {
      for (const [key, errs] of Object.entries<Object[]>(errors)) {
        if (key === "nonFieldErrors") {
          _errors.push(`${errs[0]}`);
        } else {
          let _key = key in ERROR_KEY_LOOKUP ? ERROR_KEY_LOOKUP[key] : key;
          _key = _key.replaceAll("_", " ");
          _key = toTitleCase(_key);
          _errors.push(
            `${_key}: ${
              errs[0] || "Please ensure all required fields are complete"
            }`
          );
        }
      }
    }

    if (errors.length === 0) {
      return <></>;
    }

    return (
      <AlertError title="There was an error with your submission:">
        <ul className="list-disc space-y-1 pl-5 ">
          {_errors.map((error: string, i: number) => (
            <li key={i}>{error}</li>
          ))}
        </ul>
      </AlertError>
    );
  }

  return <></>;
}
