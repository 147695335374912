import { gql } from "@apollo/client";

import { FRAGMENT_BASE_RESPONSE_FIELDS } from "api/fragments";

export const MUTATION_CREATE_EC_PRODUCT_REMORTGAGE = gql`
  mutation ECProductRemortgageCreate($input: ECProductRemortgageCreateInput!) {
    response: ecProductRemortgageCreate(input: $input) {
      ...BaseResponseFields
      product {
        id
        documents {
          mortgageOffer
          passport
        }
        canAutoRemortgage
      }
    }
  }
  ${FRAGMENT_BASE_RESPONSE_FIELDS}
`;

export const MUTATION_UPDATE_EC_PRODUCT_REMORTGAGE = gql`
  mutation ECProductRemortgageUpdate($input: ECProductRemortgageInput!) {
    response: ecProductRemortgageUpdate(input: $input) {
      ...BaseResponseFields
      product {
        id
        illustration
        selectedMortgageOption
        manualData
        applicationData
        documents {
          mortgageOffer
          passport
          payslips
          bankStatements
        }
      }
    }
  }
  ${FRAGMENT_BASE_RESPONSE_FIELDS}
`;
