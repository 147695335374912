import { useMutation } from "@apollo/client";
import {
  ArrowRightOnRectangleIcon,
  ArrowsRightLeftIcon,
  CheckBadgeIcon,
  EnvelopeIcon,
  IdentificationIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";

import CTAButton from "components/CTA/Button";

import { MUTATION_UPDATE_ACCOUNT_META } from "api/mutations/account";
import IconAhauz from "components/Image/Icons/Ahauz";
import Loading from "components/Loading";
import ModalDialog from "components/ModalDialog";
import ModalTitle from "components/ModalDialog/ModalTitle";
import { CommonObjectType } from "ts/types";
import ConnectGmail from "./ConnectGmail";
import ManualDocUpload from "./ManualDocUpload";
import StartProductFromDocs from "./StartProductFromDocs";

export default function TriggerGetStartedDocs() {
  const [modalOpen, setModalOpen] = useState(false);
  const [loadingGetStartedProcess, setLoadingGetStartedProcess] =
    useState<boolean>(false);
  const [getStartedError, setGetStartedError] = useState<boolean>(false);
  const [getStartedSuccess, setGetStartedSuccess] = useState<boolean>(false);

  const [
    updateAccountMeta,
    { data: mutationData, error: mutationError, reset: resetMutation },
  ] = useMutation(MUTATION_UPDATE_ACCOUNT_META);

  useEffect(() => {
    if (mutationData?.response) {
      if (mutationData?.response.ok) {
        setGetStartedSuccess(true);
      } else {
        setGetStartedError(true);
      }
      setTimeout(() => setLoadingGetStartedProcess(false), 500);
    }
  }, [mutationData]);

  useEffect(() => {
    if (mutationError) {
      setLoadingGetStartedProcess(false);
      setGetStartedError(true);
    }
  }, [mutationError]);

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleGetStartedSuccess = (connectionMeta: CommonObjectType) => {
    setLoadingGetStartedProcess(true);

    updateAccountMeta({
      variables: {
        input: {
          meta: connectionMeta,
        },
      },
    });
  };

  const handleGetStartedError = (error: boolean) => {
    if (error) {
      setGetStartedSuccess(false);
      resetMutation();
    }
    setGetStartedError(error);
  };

  const resetGetStartedWorkflow = () => {
    setGetStartedError(false);
    setGetStartedSuccess(false);
    resetMutation();
  };

  return (
    <>
      <CTAButton label="Get Started >" onClick={() => setModalOpen(true)} />

      <ModalDialog open={modalOpen} closeModal={closeModal}>
        {getStartedSuccess ? (
          <StartProductFromDocs handleError={handleGetStartedError} />
        ) : (
          <div className="text-center">
            <div className="mb-5 px-8">
              <ModalTitle title="How are we remortgaging you in under 5 minutes?" />
            </div>

            <div className="mb-5 flex items-center justify-center space-x-4">
              <EnvelopeIcon className="h-12 w-12 rounded-lg border p-1" />
              <ArrowsRightLeftIcon className="h-6 w-6" />
              <div className="h-12 w-12 rounded-lg border p-1 pt-1.5">
                <IconAhauz />
              </div>
            </div>

            <p className="mb-4 px-8 leading-tight">
              Connect your inbox and we'll automatically pull the documents
              required
            </p>

            {loadingGetStartedProcess ? (
              <div className="mb-5">
                <Loading />
              </div>
            ) : (
              <>
                {getStartedError && (
                  <div className="mb-3 inline-block rounded-2.5xl border border-red-800 bg-red-100 px-6 py-2 text-base text-red-800">
                    <p>
                      Error connecting your Google account, please try again
                    </p>
                  </div>
                )}

                <ConnectGmail
                  handleSuccess={handleGetStartedSuccess}
                  handleError={handleGetStartedError}
                  handleReset={resetGetStartedWorkflow}
                />

                <div className="mb-5">
                  <ManualDocUpload />
                </div>
              </>
            )}

            <div className="rounded-lg border px-5 py-4 text-left text-sm">
              <ul className="space-y-2">
                <li>
                  <div className="relative flex items-center space-x-2">
                    <div className="flex-shrink-0">
                      <IdentificationIcon className="h-auto w-6" />
                    </div>
                    <div className="min-w-0 flex-1">
                      <p>You approve which documents we can access</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="relative flex items-center space-x-2">
                    <div className="flex-shrink-0">
                      <CheckBadgeIcon className="h-auto w-6" />
                    </div>
                    <div className="min-w-0 flex-1">
                      <p>
                        You select the product you want out of the best options
                        for you
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="relative flex items-center space-x-2">
                    <div className="flex-shrink-0">
                      <ArrowRightOnRectangleIcon className="h-auto w-6" />
                    </div>
                    <div className="min-w-0 flex-1">
                      <p>We apply on your behalf</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        )}
      </ModalDialog>
    </>
  );
}
