export default function IconAhauz() {
  return (
    <svg
      width="39"
      height="32"
      viewBox="0 0 39 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2927 0L0 13.1396H6.09659V32L19.2927 3.48951L32.4887 32V13.1396H38.5843L19.2927 0Z"
        fill="#0D493B"
      />
    </svg>
  );
}
