import { CommonProps } from "ts/types";

export default function ProductHeader({ title, children }: CommonProps) {
  return (
    <div className="mx-auto w-full px-4 text-center font-medium md:max-w-2xl">
      <p className="text-2xl">{title}</p>
      {children}
    </div>
  );
}
