import { useMutation } from "@apollo/client";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { trackPage } from "analytics";
import { MUTATION_CREATE_EC_PRODUCT_REMORTGAGE } from "api/mutations/products";
import CTAButton from "components/CTA/Button";
import FormErrors from "components/Form/Errors";
import { validateSingleFile } from "components/Form/Input/FileButton";
import Loading from "components/Loading";
import { getRootChildRoute } from "routes/utils";
import { APP_URLS } from "settings";
import {
  DEFAULT_PRODUCT_PROGRESS,
  PRODUCT_STEPS,
  useProductsState,
} from "store/Products";
import { useUserStore } from "store/User";
import ManualDocUploadAction from "../components/ManualDocUploadAction";
import ProductHeader from "../components/ProductHeader";

type FormInputs = {
  fileMortgageOffer: FileList | null;
  filePassport: FileList | null;
};

const schema = yup.object().shape({
  fileMortgageOffer: validateSingleFile("Mortgage Offer"),
  filePassport: validateSingleFile("Passport"),
});

export default function UploadDocs() {
  const navigate = useNavigate();

  const [submitError, setSubmitError] = useState<string | null>(null);
  const [loadingDocs, setLoadingDocs] = useState<boolean>(false);
  const [loadingDocsComplete, setLoadingDocsComplete] =
    useState<boolean>(false);
  const [uploadNewDocs, setUploadNewDocs] = useState<boolean>(false);

  const equityCheck = useUserStore((state) => state.equityCheck);

  const productRemortgage = useProductsState(
    (state) => state.productRemortgage
  );

  const setRemortgageProgress = useProductsState(
    (state) => state.setRemortgageProgress
  );

  const [
    createRemortgageProduct,
    { data: mutationData, error: mutationError, loading: mutationLoading },
  ] = useMutation(MUTATION_CREATE_EC_PRODUCT_REMORTGAGE);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      fileMortgageOffer: null,
      filePassport: null,
    },
  });

  const uploadedMortgageOffer = watch("fileMortgageOffer");
  const uploadedPassport = watch("filePassport");

  const formErrors =
    mutationError?.message ||
    submitError ||
    Array.from(Object.values(errors), (error) => error.message);

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    setSubmitError(null);
    setLoadingDocs(false);

    createRemortgageProduct({
      variables: {
        input: {
          id: equityCheck?.id,
          manualUploads: {
            mortgageOffer: data.fileMortgageOffer,
            passport: data.filePassport,
          },
        },
      },
    });
  };

  useEffect(() => {
    trackPage("Upload Documents");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mutationData?.response) {
      const responseOk = mutationData.response?.ok;
      const docsUploaded = mutationData.response?.product?.documents;

      if (!responseOk || !docsUploaded) {
        setSubmitError(
          "Sorry, there has been an error submitting your documents. Please try again or contact our Customer Success team."
        );
        return;
      }

      const { __typename, ...docTypes } = docsUploaded;
      const missingDocs = Object.values(docsUploaded).some(
        (value) => value === false
      );

      if (missingDocs) {
        setSubmitError(
          "Please check you have uploaded all required documents."
        );
        return;
      }

      setLoadingDocs(true);

      window.scrollTo(0, 0);

      setRemortgageProgress(
        {
          ...DEFAULT_PRODUCT_PROGRESS,
          ...{
            id: mutationData.response?.product?.id,
            step: PRODUCT_STEPS.REMORTGAGE.DEALS,
            documents: docTypes,
            hasRequiredDocs: true,
            canAutoRemortgage:
              mutationData.response?.product?.canAutoRemortgage,
          },
        },
        true
      );

      setTimeout(() => setLoadingDocsComplete(true), 4500);
      setTimeout(() => navigateToDeals(), 5250);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationData]);

  const navigateToDeals = () =>
    navigate(
      getRootChildRoute(
        APP_URLS.PRODUCT_PAGES.REMORTGAGE,
        PRODUCT_STEPS.REMORTGAGE.DEALS
      )
    );

  return (
    <>
      <ProductHeader title="Let's make sure you are always on the best deal">
        <div className="text-center text-lg font-normal">
          <p className="mt-2">
            Don't worry about your next deal. We'll be on the lookout for a
            better one. Forever...
          </p>
          <p className="mt-1">For that, we need your:</p>
        </div>
      </ProductHeader>

      <div className="mx-0 rounded-lg bg-gray-100 py-6 md:mx-6">
        {loadingDocs ? (
          loadingDocsComplete ? (
            <>
              <CheckCircleIcon className="bg-green mx-auto h-8 w-8" />
              <p className="mb-t mx-4 text-center">
                Success! We'll now find you the best deals...
              </p>
            </>
          ) : (
            <>
              <Loading />
              <p className="mx-4 mt-3 text-center">Uploading documents...</p>
            </>
          )
        ) : productRemortgage?.hasRequiredDocs && !uploadNewDocs ? (
          <>
            <CheckCircleIcon className="bg-green mx-auto h-8 w-8" />
            <p className="mx-4 mb-1 text-center">
              Success! You're documents are uploaded.
            </p>
            <button
              className="link mx-auto block"
              onClick={() => navigateToDeals()}
            >
              View Deals
            </button>
            <button
              className="link mx-auto block"
              onClick={() => setUploadNewDocs(true)}
            >
              Upload new documents
            </button>
          </>
        ) : (
          <form
            encType="multipart/form-data"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            className="mx-auto flex w-full max-w-sm flex-col space-y-6 px-4 sm:px-0"
          >
            <ManualDocUploadAction
              docID="fileMortgageOffer"
              docType="Mortgage Offer"
              helper="Share your most recent Mortgage Offer, so we know exactly your current deal"
              formats=".pdf"
              requiredCount={1}
              uploadedDoc={uploadedMortgageOffer}
              register={register}
              setValue={setValue}
            />
            <ManualDocUploadAction
              docID="filePassport"
              docType="Passport"
              helper="For secure identity verification"
              uploadedDoc={uploadedPassport}
              formats="image/*,.pdf"
              requiredCount={1}
              register={register}
              setValue={setValue}
            />

            {formErrors && <FormErrors errors={formErrors} />}

            <div className="text-center">
              <CTAButton
                type="submit"
                label="Upload & Continue >"
                loading={mutationLoading}
              />
            </div>
          </form>
        )}
      </div>

      {/* <button
        className="link mx-auto block"
        onClick={() => navigate(APP_URLS.DASHBOARD)}
      >
        I'm not ready
      </button> */}
    </>
  );
}
