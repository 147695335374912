import { createSearchParams } from "react-router-dom";
import { CommonObjectType } from "ts/types";

/**
 * Get URL query params and build string for passing on to forwarding URL
 * @param searchParams URLSearchParams
 * @returns string
 */
export const passOnSearchParams = (searchParams: any) => {
  if (!searchParams) {
    return "";
  }

  const _params: Array<any> = Array.from(searchParams.entries());

  if (_params.length > 0) {
    const params: any = {};

    for (const [key, value] of _params) {
      params[key] = value;
    }

    return `?${createSearchParams(params).toString()}`;
  }

  return "";
};

export const getRootChildRoute = (routeObj: CommonObjectType, child: string) =>
  `${routeObj.ROOT}${routeObj[child]}`;
