import { ArcElement, Chart, DoughnutController } from "chart.js";
import { useEffect } from "react";

import STATS, { getStatChartValue } from "components/Dashboard/Stats/config";
import { useUserStore } from "store/User";
import { EquityCheckType } from "ts/types/equitycheck";
import { classNames } from "utils/helpers";

Chart.register(DoughnutController, ArcElement);

export default function EquityChart({
  chartID,
  equityCheck,
}: {
  chartID: string;
  equityCheck?: EquityCheckType | null;
}) {
  const { COPY } = useUserStore((state) => state.userCountry);
  const equityCheckIsSuccess = useUserStore(
    (state) => state.equityCheckIsSuccess
  );

  const showChartData = equityCheckIsSuccess;
  const showSecondMortgage =
    showChartData && equityCheck?.live?.secondMortgageBalance;

  useEffect(() => {
    let chart;

    chart = Chart.getChart(chartID);

    if (chart) {
      chart.destroy();
    }

    const dataPoints =
      showChartData &&
      !(
        !equityCheck?.live?.propertyEquityPercent &&
        equityCheck?.live?.propertyEquityPercent !== 0
      ) &&
      !(
        !equityCheck?.live?.propertyMortgagePercent &&
        equityCheck?.live?.propertyMortgagePercent !== 0
      )
        ? [
            getStatChartValue(equityCheck.live.propertyEquityPercent),
            getStatChartValue(equityCheck.live.propertyMortgagePercent),
          ]
        : [50, 50];

    const dataPointColours = [STATS.equity.color, STATS.mortgage.color];

    if (showChartData && equityCheck?.live?.secondMortgageBalance) {
      dataPoints.push(equityCheck.live.propertySecondMortgagePercent);
      dataPointColours.push(STATS.secondMortgage.color);
    }

    const data = {
      datasets: [
        {
          data: dataPoints,
          backgroundColor: dataPointColours,
          hoverOffset: 8,
          hoverBorderColor: "white",
          borderWidth: 8,
        },
      ],
    };

    chart = new Chart(chartID, {
      type: "doughnut",
      data: data,
      options: {
        radius: "100%",
        cutout: "90%",
        borderColor: "white",
        animation: {
          duration: 1000,
        },
        spacing: 0,
        events: [],
        layout: {
          padding: {
            top: -10,
          },
        },
      },
    });
  }, [chartID, showChartData, equityCheck]);

  const renderChartVal = (
    color: string,
    title: string,
    percentVal: number | undefined
  ) => {
    const chartClass = `chart-${title
      .toLocaleLowerCase()
      .replace(/\s+/g, "-")}`;

    return (
      <div className={chartClass} style={{ color }}>
        <p
          className={classNames(
            showSecondMortgage ? "text-md" : "text-lg",
            "font-bold md:text-xl"
          )}
        >
          {title}
        </p>
        <p
          className={classNames(
            showSecondMortgage ? "text-5xl" : "text-6xl",
            "md:text-7xl",
            "chart-val"
          )}
        >
          {showChartData && (percentVal || percentVal === 0)
            ? `${percentVal}%`
            : "-"}
        </p>
      </div>
    );
  };

  return (
    <div className={`${chartID} relative w-[500px] max-w-md`}>
      <canvas
        id={chartID}
        className="relative z-10 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundSize: "65%",
        }}
      />
      <div className="brand-hero absolute bottom-0 left-0 right-0 top-0 z-0 m-auto h-[98%] w-[98%] rounded-full shadow-2xl">
        <div className="relative flex h-full w-full items-center justify-center">
          <div className="flex flex-col items-center space-y-4 p-6 text-center [text-shadow:_0px_2px_19px_rgba(0,0,0,0.35)]">
            {renderChartVal(
              STATS.equity.color,
              "Your Home Equity",
              equityCheck?.live?.propertyEquityPercent
            )}

            <hr className="w-12 bg-white" />

            {renderChartVal(
              STATS.mortgage.color,
              COPY.VALUE_LABELS.MORTGAGE_BALANCE,
              equityCheck?.live?.propertyMortgagePercent
            )}

            {showSecondMortgage &&
              renderChartVal(
                STATS.secondMortgage.color,
                "Second Mortgage",
                equityCheck?.live?.propertySecondMortgagePercent
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
