export const ROOT_HOST = process.env.REACT_APP_ROOT_HOST as string;

export const WEB_URLS = {
  HOME: process.env.REACT_APP_WEB as string,
  BROKER_PORTAL: process.env.REACT_APP_BROKER_PORTAL as string,
  CDN: {
    IMAGES: `${process.env.REACT_APP_CDN as string}img/`,
  },
};

export const IS_DEV = process.env.REACT_APP_ENV === "dev";
export const IS_STAGING = process.env.REACT_APP_ENV === "staging";
export const IS_PROD = process.env.REACT_APP_ENV === "production";

export const IS_TEST_ENV = IS_DEV || IS_STAGING;
export const IS_REAL_ENV = IS_STAGING || IS_PROD;

export const AHAUZ_PHONE_NUMBER = "0330 818 7020";

export const APP_URLS = {
  DASHBOARD: "/",
  LOGIN_MAGIC: "/login/:magic",
  LOGIN: "/login",
  GET_STARTED: {
    ROOT: "/get-started/",
    MAIN: "/get-started/*",
    PROPERTY: "/",
    ACCOUNT: "/account",
    COMPLETE: "/complete",
  },
  ACCOUNT: {
    ACCOUNT_PROFILE: "/account/profile",
    ACCEPT_INVITE: "/account/accept-invite/:token",
    ACCOUNT_VERIFICATION: "/account/verification",
    RESET_PASSWORD: "/account/reset-password/:uidb64/:token",
    FORGOT_PASSWORD: "/account/forgot-password",
  },
  EQUITY_TIMELINE: "/timeline",
  PRODUCT_PAGES: {
    REMORTGAGE: {
      ROOT: "/remortgage",
      MAIN: "/remortgage/*",
      START: "/",
      UPLOAD_DOCS: "/upload-documents",
      DOCS: "/documents",
      DEALS: "/deals",
      ILLUSTRATION: "/illustration",
      APPLICATION: "/application",
      REVIEW: "/review",
      SUBMITTED: "/submitted",
      TALK_TO_US: "/talk-to-us",
    },
  },
};

export const CRM = {
  APPOINTMENT_BOOKING_URL: process.env
    .REACT_APP_CRM_APPOINTMENT_BOOKING_URL as string,
  APPOINTMENT_BOOKING_URL_REMORTGAGE: process.env
    .REACT_APP_CRM_APPOINTMENT_BOOKING_URL_REMORTGAGE as string,
  APPOINTMENT_BOOKING_URL_BUY_PROPERTY: process.env
    .REACT_APP_CRM_APPOINTMENT_BOOKING_URL_BUY_PROPERTY as string,
  APPOINTMENT_BOOKING_URL_EQUITY_LOAN: process.env
    .REACT_APP_CRM_APPOINTMENT_BOOKING_URL_EQUITY_LOAN as string,
  APPOINTMENT_BOOKING_URL_DEBT_CONSOLIDATION: process.env
    .REACT_APP_CRM_APPOINTMENT_BOOKING_URL_DEBT_CONSOLIDATION as string,
  APPOINTMENT_BOOKING_URL_EQUITY_RELEASE: process.env
    .REACT_APP_CRM_APPOINTMENT_BOOKING_URL_EQUITY_RELEASE as string,
};

export const ANALYTICS = {
  GA: {
    LEAD_CONVERSION: process.env.REACT_APP_GA_GTM_LEAD_CONVERSION as string,
    CALL_CONVERSION: process.env.REACT_APP_GA_GTM_CALL_CONVERSION as string,
  },
};

export const EXTERNAL_API = {
  IP_LOOKUP: process.env.REACT_APP_IP_COUNTRY_CODE_LOOKUP as string,
};

export const COOKIES = {
  CSRF: "csrftoken",
  SIGNUP_LANDING_PAGE: "ahauz-landing-page",
};

export const LEADS = {
  CONTACT_STATE: {
    LANDING_PAGE_ID: "c6424c39-d0c6-467e-91a7-323190f267ea",
    CERTIFY_SCRIPT_ID: "cscertify" as string,
    CERTIFY_URL: "https://js.contactstate.com/certify-latest.js",
  },
};

export const SERVICES = {
  GOOGLE: {
    EMAIL_DOC_PARSER: {
      CLIENT_ID: process.env
        .REACT_APP_G_API_EMAIL_DOC_PARSER_CLIENT_ID as string,
      API_KEY: process.env.REACT_APP_G_API_EMAIL_DOC_PARSER_KEY as string,
    },
  },
};
