export function ProductCTAIcon({
  size = "12",
  children,
}: {
  size?: string;
  children: JSX.Element;
}) {
  return (
    <span
      className={`z-10 inline flex h-${size} w-${size} items-center justify-center rounded-full bg-brand-green-medium text-3xl shadow-[0px_0px_0px_8px_rgba(127,246,215,0.3)]`}
    >
      {children}
    </span>
  );
}
