import { Dialog } from "@headlessui/react";

export default function ModalTitle({ title }: { title: string }) {
  return (
    <Dialog.Title
      as="h3"
      className="text-lg font-medium leading-6 text-brand-green-dark"
    >
      {title}
    </Dialog.Title>
  );
}
