import { DEFAULT_COUNTRY } from "country-config";

/**
 * Convert an input value to a float
 */
export const inputToFloat = (input: any) => {
  if (input) {
    try {
      return parseFloat(input.replaceAll(",", ""));
    } catch (error) {
      return input;
    }
  }
  return input;
};

/**
 * Return value in money format
 */
export const asCurrency = ({
  value,
  countryCode = DEFAULT_COUNTRY,
  allowZero = false,
  rounding = true,
}: {
  value: any;
  countryCode?: string;
  allowZero?: boolean;
  rounding?: boolean;
}) => {
  // TODO: Should rounding be false by default?
  if (allowZero && value === 0) {
    return "0";
  }
  if (value) {
    if (rounding) {
      const MoneyFormat = new Intl.NumberFormat(`en-${countryCode}`, {
        maximumFractionDigits: 2,
      });
      return MoneyFormat.format(value);
    }

    const MoneyFormat = new Intl.NumberFormat(`en-${countryCode}`, {
      maximumFractionDigits: 2,
      style: "currency",
      currency: "GBP",
      currencyDisplay: "code",
    });
    return MoneyFormat.format(value).replace("GBP", "").trim();
  }
  return "";
};
