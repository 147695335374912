import {
  DocumentCheckIcon,
  DocumentIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";

import FormInputFileButton from "components/Form/Input/FileButton";

type ManualDocUploadActionType = {
  docID: string;
  docType: string;
  helper: string;
  formats: string;
  requiredCount?: number;
  uploadedDoc: FileList | null;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
};

export default function ManualDocUploadAction({
  docID,
  docType,
  helper,
  formats,
  requiredCount = 1,
  uploadedDoc,
  register,
  setValue,
}: ManualDocUploadActionType) {
  const label =
    uploadedDoc && requiredCount === 0 ? "Change file" : "Upload file";

  const handleRemoveFile = (index: number) => {
    if (uploadedDoc) {
      const existingFiles = Array.from(uploadedDoc);
      const updatedFiles = existingFiles.filter((_, i) => i !== index);

      let _tmpFiles = new DataTransfer();

      for (const file of updatedFiles) {
        _tmpFiles.items.add(file);
      }

      setValue(docID, _tmpFiles.files);
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const existingFiles = uploadedDoc;

      if (!existingFiles) {
        return;
      }

      const existingFilesArray = Array.from(existingFiles);
      const newFilesArray = Array.from(event.target.files);

      let _tmpFiles = new DataTransfer();

      for (const file of [...existingFilesArray, ...newFilesArray]) {
        _tmpFiles.items.add(file);
      }

      setValue(docID, _tmpFiles.files);
      event.target.files = _tmpFiles.files;
      return;
    }
  };

  return (
    <div className="rounded-lg bg-white p-3 shadow">
      <span className="block text-lg font-medium">{docType}</span>
      <ul className="mt-2">
        {uploadedDoc && uploadedDoc.length > 0 ? (
          Array.from(uploadedDoc).map((file, i) => (
            <li key={i} className="flex items-center justify-between">
              <span className="flex items-center">
                <DocumentCheckIcon className="mr-1 h-8 w-8 text-brand-green-medium" />
                {file.name}
              </span>
              <button type="button" onClick={() => handleRemoveFile(i)}>
                <XCircleIcon className="h-6 w-6 text-red-800" />
              </button>
            </li>
          ))
        ) : (
          <li className="flex flex-row items-center">
            <DocumentIcon className="mr-2 inline h-8 w-8 basis-1/12 text-gray-200" />
            <span className="basis-11/12">{helper}</span>
          </li>
        )}
      </ul>

      {!(requiredCount > 0 && uploadedDoc?.length === requiredCount) && (
        <div className="mb-1 mt-4 flex flex-col">
          <FormInputFileButton
            id={docID}
            register={register}
            label={label}
            formats={formats}
            onChange={handleOnChange}
          />
        </div>
      )}
    </div>
  );
}
