import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { ReceiptPercentIcon } from "@heroicons/react/24/outline";
import { Link, Route, Routes, useLocation } from "react-router-dom";

import Dashboard from "components/Dashboard";
import Container from "components/Dashboard/Container";
import { ProductCTAIcon } from "components/EquityCheck/Actions/components/CTAIcon";
import { useEffect } from "react";
import { getRootChildRoute } from "routes/utils";
import { APP_URLS } from "settings";
import { PRODUCT_STEPS, useProductsState } from "store/Products";
import { classNames } from "utils/helpers";
import ProductRemortgageMain from "./Main";
import DocsResult from "./children/DocsResult";
import Illustration from "./children/Illustration";
import RemortgageDeals from "./children/RemortgageDeals";
import Review from "./children/Review";
import Submitted from "./children/Submitted";
import TalkToUs from "./children/TalkToUs";
import UploadDocs from "./children/UploadDocs";
import ProductHeader from "./components/ProductHeader";

type BreadcrumbStepType = {
  name: string;
  route: string;
  step: string;
};

type BreadcrumbStepsType = {
  [key: string]: BreadcrumbStepType;
};

const BREADCRUMB_PAGES: BreadcrumbStepsType = {
  documents: {
    name: "Documents",
    route: getRootChildRoute(
      APP_URLS.PRODUCT_PAGES.REMORTGAGE,
      PRODUCT_STEPS.REMORTGAGE.UPLOAD_DOCS
    ),
    step: PRODUCT_STEPS.REMORTGAGE.UPLOAD_DOCS,
  },
  deals: {
    name: "Deals",
    route: getRootChildRoute(
      APP_URLS.PRODUCT_PAGES.REMORTGAGE,
      PRODUCT_STEPS.REMORTGAGE.DEALS
    ),
    step: PRODUCT_STEPS.REMORTGAGE.DEALS,
  },
  review: {
    name: "Review",
    route: getRootChildRoute(
      APP_URLS.PRODUCT_PAGES.REMORTGAGE,
      PRODUCT_STEPS.REMORTGAGE.REVIEW
    ),
    step: PRODUCT_STEPS.REMORTGAGE.REVIEW,
  },
  submitted: {
    name: "Submitted",
    route: getRootChildRoute(
      APP_URLS.PRODUCT_PAGES.REMORTGAGE,
      PRODUCT_STEPS.REMORTGAGE.SUBMITTED
    ),
    step: PRODUCT_STEPS.REMORTGAGE.SUBMITTED,
  },
};

const STEP_PATH_STATE_MAP = {
  [APP_URLS.PRODUCT_PAGES.REMORTGAGE.START]: PRODUCT_STEPS.START,
  [getRootChildRoute(
    APP_URLS.PRODUCT_PAGES.REMORTGAGE,
    PRODUCT_STEPS.REMORTGAGE.DOCS
  )]: PRODUCT_STEPS.REMORTGAGE.UPLOAD_DOCS,
  [BREADCRUMB_PAGES.documents.route]: PRODUCT_STEPS.REMORTGAGE.UPLOAD_DOCS,
  [BREADCRUMB_PAGES.deals.route]: PRODUCT_STEPS.REMORTGAGE.DEALS,
  [getRootChildRoute(
    APP_URLS.PRODUCT_PAGES.REMORTGAGE,
    PRODUCT_STEPS.REMORTGAGE.ILLUSTRATION
  )]: PRODUCT_STEPS.REMORTGAGE.REVIEW,
  [BREADCRUMB_PAGES.review.route]: PRODUCT_STEPS.REMORTGAGE.REVIEW,
  [BREADCRUMB_PAGES.submitted.route]: PRODUCT_STEPS.REMORTGAGE.SUBMITTED,
};

function NotFound() {
  return (
    <>
      <ProductHeader title="Sorry, there has been an error loading this page." />
      <p className="text-md mx-4 mt-4 text-center">
        Please try again later or contact our Customer Service team to discuss
        your options.
      </p>
    </>
  );
}

export default function ProductRemortgage() {
  const location = useLocation();

  const currentStep = useProductsState((state) => state.productApplicationStep);

  const productApplicationStepsComplete = useProductsState(
    (state) => state.productApplicationStepsComplete
  );

  const setProductApplicationStep = useProductsState(
    (state) => state.setProductApplicationStep
  );

  useEffect(() => {
    setProductApplicationStep(STEP_PATH_STATE_MAP[location.pathname]);
  }, [location, setProductApplicationStep]);

  const renderBreadcumbStep = (page: BreadcrumbStepType) => {
    const isCurrentStep: boolean = page.step === currentStep;
    const canNavigateToStep: boolean = productApplicationStepsComplete.includes(
      page.step
    );
    const isSubitted: boolean =
      currentStep === PRODUCT_STEPS.REMORTGAGE.SUBMITTED;
    const isFirstStep = page.step === PRODUCT_STEPS.REMORTGAGE.UPLOAD_DOCS;

    return (
      <li key={page.name}>
        <div className="flex items-center">
          {!isFirstStep && (
            <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" />
          )}
          <span
            className={classNames(
              isFirstStep ? "ml-0" : "ml-2 md:ml-4",
              "text-base"
            )}
          >
            {isSubitted ? (
              <span
                className={classNames(
                  isCurrentStep
                    ? "text-brand-green-medium"
                    : "text-brand-green-dark"
                )}
              >
                {page.name}
              </span>
            ) : isCurrentStep || !canNavigateToStep ? (
              <span
                className={classNames(
                  isCurrentStep ? "text-brand-green-medium" : "text-gray-400"
                )}
              >
                {page.name}
              </span>
            ) : (
              <Link to={page.route} className="text-brand-green-dark">
                {page.name}
              </Link>
            )}
          </span>
        </div>
      </li>
    );
  };

  return (
    <Dashboard title="Remortgage" overlap>
      <div className="px-0 lg:px-8">
        <Container>
          <>
            {currentStep ? (
              <nav className="mx-2 flex justify-center border-b border-gray-200 py-5 md:mx-8 md:justify-start">
                <div className="mr-6 hidden md:block">
                  <ProductCTAIcon size="10">
                    <ReceiptPercentIcon className="h-7 w-7 text-brand-green-dark" />
                  </ProductCTAIcon>
                </div>
                <ol className="flex items-center space-x-2 md:space-x-4">
                  {Object.entries(BREADCRUMB_PAGES).map(([key, value]) =>
                    renderBreadcumbStep(value)
                  )}
                </ol>
              </nav>
            ) : (
              <div className="flex justify-center pt-8">
                <ProductCTAIcon size="16">
                  <ReceiptPercentIcon className="h-12 w-12" />
                </ProductCTAIcon>
              </div>
            )}
            <div className="space-y-6 py-8">
              <Routes>
                <Route
                  path={APP_URLS.PRODUCT_PAGES.REMORTGAGE.SUBMITTED}
                  element={<Submitted />}
                />
                <Route
                  path={APP_URLS.PRODUCT_PAGES.REMORTGAGE.REVIEW}
                  element={<Review />}
                />
                <Route
                  path={APP_URLS.PRODUCT_PAGES.REMORTGAGE.ILLUSTRATION}
                  element={<Illustration />}
                />
                <Route
                  path={APP_URLS.PRODUCT_PAGES.REMORTGAGE.DEALS}
                  element={<RemortgageDeals />}
                />
                <Route
                  path={APP_URLS.PRODUCT_PAGES.REMORTGAGE.DOCS}
                  element={<DocsResult />}
                />
                <Route
                  path={APP_URLS.PRODUCT_PAGES.REMORTGAGE.UPLOAD_DOCS}
                  element={<UploadDocs />}
                />
                <Route
                  path={APP_URLS.PRODUCT_PAGES.REMORTGAGE.START}
                  element={<ProductRemortgageMain />}
                />
                <Route
                  path={APP_URLS.PRODUCT_PAGES.REMORTGAGE.TALK_TO_US}
                  element={<TalkToUs />}
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </>
        </Container>
      </div>
    </Dashboard>
  );
}
