import { XCircleIcon } from "@heroicons/react/20/solid";

type Props = {
  title?: string;
  children: JSX.Element;
  small?: boolean;
};

export default function AlertError({ title, children, small = false }: Props) {
  return (
    <div
      className={`rounded-2.5xl border border-red-800 bg-red-100 ${
        small ? "p-2" : "p-4"
      }`}
    >
      <div className={`${title ? "flex" : "flex items-center"}`}>
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-800" aria-hidden="true" />
        </div>
        <div className="ml-3 text-base text-red-800">
          {title && <p className="text-base font-medium">{title}</p>}
          <div className={title ? "mt-2" : ""}>{children}</div>
        </div>
      </div>
    </div>
  );
}
