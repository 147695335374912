import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  ProductRemortgageApplicationDataMapType,
  ProductRemortgageApplicationFieldsType,
} from "store/Products/types";
import { CommonObjectType } from "ts/types";
// import { VALIDATION_REQUIRED } from "utils/validation";

export const APPLICATION_DATA_MAP: ProductRemortgageApplicationDataMapType = {
  customer_information: {
    order: 0,
    fieldset: "Customer Information",
    fields: {
      title: {
        order: 0,
        label: "Title",
      },
      forename: {
        order: 1,
        label: "Forename",
      },
      surname: {
        order: 2,
        label: "Surname",
      },
      gender: {
        order: 3,
        label: "Gender",
      },
      date_of_birth: {
        order: 4,
        label: "Date of Birth",
      },
      marital_status: {
        order: 5,
        label: "Marital Status",
      },
      name_ever_changed: {
        order: 6,
        label: "Has your name ever changed",
        format: "yesno",
      },
      nationality: {
        order: 7,
        label: "Nationality",
      },
      dual_nationality: {
        order: 8,
        label: "Dual Nationality",
        format: "yesno",
      },
      country_of_residence: {
        order: 9,
        label: "Country of Residence",
      },
      diplomatic_immunity: {
        order: 10,
        label: "Do you have diplomatic immunity",
        format: "yesno",
      },
      contact_number: {
        order: 11,
        label: "Contact number",
      },
      email: {
        order: 12,
        label: "Email Address",
      },
      current_address: {
        order: 13,
        label: "Current Address",
      },
      postcode: {
        order: 14,
        label: "Postcode",
      },
      address_changed_last3years: {
        order: 15,
        label: "Has your address changed in the last 3 years",
        format: "yesno",
      },
      date_moved_into_current_address: {
        order: 16,
        label: "Date moved into current address",
      },
      residential_status: {
        order: 17,
        label: "Residential Status",
      },
      special_needs: {
        order: 18,
        label: "Special Needs",
        format: "yesno",
      },
      dependent_adults: {
        order: 19,
        label: "How many dependent adults do you have",
        format: "number",
      },
      dependent_children: {
        order: 20,
        label: "How many dependent children do you have",
        format: "number",
      },
      years_with_current_bank: {
        order: 21,
        label: "Number of years with current bank",
        format: "number",
      },
    },
  },
  address_history: {
    order: 1,
    fieldset: "Address history",
    fields: {
      address: {
        order: 0,
        label: "Address",
        required: false,
      },
      from: {
        order: 1,
        label: "From",
        required: false,
      },
      to: {
        order: 2,
        label: "To",
        required: false,
      },
    },
  },
  employment_details: {
    order: 2,
    fieldset: "Current Employment",
    fields: {
      number_of_jobs_per_applicant: {
        order: 0,
        label: "Number of jobs per applicant",
        format: "number",
      },
      type_of_occupation: {
        order: 1,
        label: "Type of occupation",
      },
      employment_type: {
        order: 2,
        label: "Employment type",
      },
      contract_type: {
        order: 3,
        label: "Contract type",
      },
      employment_start_date: {
        order: 4,
        label: "Start date of employment",
      },
      percent_of_business_owned: {
        order: 5,
        label: "Percentage of business owned",
        format: "percent",
        required: false,
      },
      anticipated_retirement_age: {
        order: 6,
        label: "Anticipated age of retirement",
        format: "number",
      },
      name_of_employer_or_business: {
        order: 7,
        label: "Name of employer/business",
      },
      employer_business_address: {
        order: 8,
        label: "Employer's/business address",
      },
      employer_business_contact_numbers: {
        order: 9,
        label: "Employer's/business telephone number",
      },
      income_from_other_sources: {
        order: 10,
        label: "Do you have income from any other sources",
        format: "yesno",
      },
    },
  },
  income: {
    order: 3,
    fieldset: "Income",
    fields: {
      basic_salary: {
        order: 0,
        label: "Gross basic salary",
        format: "currency",
      },
      annual_commission: {
        order: 1,
        label: "Annual commission",
        format: "currency",
        required: false,
      },
      annual_bonus: {
        order: 2,
        label: "Annual bonus",
        format: "currency",
        required: false,
      },
      annual_overtime: {
        order: 3,
        label: "Annual overtime",
        format: "currency",
        required: false,
      },
    },
  },
  credit_commitments: {
    order: 4,
    fieldset: "Credit Commitments",
    fields: {
      type_of_credit: {
        order: 0,
        label: "Type of credit",
        required: false,
      },
      name_of_lender: {
        order: 1,
        label: "Name of lender/company",
        required: false,
      },
      monthly_repayment: {
        order: 2,
        label: "Monthly repayment",
        format: "currency",
        required: false,
      },
      balance_outstanding: {
        order: 3,
        label: "Balance outstanding",
        format: "currency",
        required: false,
      },
      end_date_of_credit: {
        order: 4,
        label: "End date of credit",
        required: false,
      },
      number_of_months_in_arrears: {
        order: 5,
        label: "Number of months in arrears",
        required: false,
      },
      repaid_in_full_on_completion: {
        order: 6,
        label: "Will this be repaid on completion in full",
        required: false,
      },
      source_of_funds_to_repay_on_completion: {
        order: 7,
        label: "If yes, source of funds to repay",
        required: false,
      },
    },
  },
  mortgage_commitments: {
    order: 5,
    fieldset: "Mortgage Commitments",
    fields: {
      mortgage_type: {
        order: 0,
        label: "Mortgage Type",
      },
      remaining_term_years: {
        order: 1,
        label: "Remaining term years",
        format: "number",
      },
      repayment_type: {
        order: 2,
        label: "Repayment type",
      },
      name_of_lender: {
        order: 3,
        label: "Name of lender/company",
      },
      monthly_repayment: {
        order: 4,
        label: "Monthly repayment",
        format: "currency",
      },
      balance_outstanding: {
        order: 5,
        label: "Balance outstanding",
        format: "currency",
      },
      end_date_of_loan: {
        order: 6,
        label: "End date of loan",
      },
      number_of_months_in_arrears: {
        order: 7,
        label: "Number of months in arrears",
        format: "number",
      },
      repaid_in_full_on_completion: {
        order: 8,
        label: "Will this be repaid on completion in full",
        format: "yesno",
      },
      source_of_funds_to_repay_on_completion: {
        order: 9,
        label: "If yes, source of funds to repay",
      },
    },
  },
  property: {
    order: 6,
    fieldset: "Property",
    fields: {
      address: {
        order: 0,
        label: "Postal address of the property",
      },
      postcode: {
        order: 1,
        label: "Postcode",
      },
      type_of_property: {
        order: 2,
        label: "What type of property is this",
      },
      number_of_bedrooms: {
        order: 3,
        label: "Number of bedrooms",
        format: "number",
      },
      flat_number_of_floors: {
        order: 4,
        label: "If the property is a flat, number of floors",
        format: "number",
        required: false,
      },
      flat_floor: {
        order: 5,
        label: "Which floor is the flat on",
        format: "number",
        required: false,
      },
      property_built_in_last12_months: {
        order: 6,
        label: "Was the property built in the last 12 months",
        format: "yesno",
      },
      year_property_built: {
        order: 7,
        label: "Year property built",
        format: "number",
      },
      own_the_property: {
        order: 8,
        label: "Do you currently own the property",
        format: "yesno",
      },
      live_at_the_property: {
        order: 9,
        label: "Do you currently live at the property",
        format: "yesno",
      },
      anyone_else_u17_live_at_property: {
        order: 10,
        label: "Will anyone else (aged 17 or over) live at the property",
        format: "yesno",
      },
      business_carried_out_at_property: {
        order: 11,
        label: "Will business be carried out at this property",
        format: "yesno",
      },
      tenure_of_property: {
        order: 12,
        label: "What is the tenure of the property",
      },
      annual_ground_rent: {
        order: 13,
        subtitle: "How much will the following be (if applicable)",
        label: "Annual ground rent",
        format: "currency",
        required: false,
      },
      annual_service_charge: {
        order: 14,
        label: "Annual service charge",
        format: "currency",
        required: false,
      },
      purchase_price_or_valuation: {
        order: 15,
        label: "Purchase price/valuation",
        format: "currency",
      },
    },
  },
  product: {
    order: 7,
    fieldset: "Product Details",
    fields: {
      type_of_product: {
        order: 0,
        label: "Type of product",
        editable: false,
      },
      interest_rate: {
        order: 1,
        label: "Interest rate charged",
        format: "percent",
        editable: false,
      },
      product_term: {
        order: 2,
        label: "Product term",
        format: "years",
        editable: false,
      },
      loan_amount: {
        order: 3,
        label: "Loan amount",
        format: "currency",
        editable: false,
      },
      loan_term_years: {
        order: 4,
        label: "Loan term",
        format: "years",
        editable: false,
      },
      repayment_type: {
        order: 5,
        label: "Repayment type",
        editable: false,
      },
      purpose: {
        order: 6,
        label: "Purpose",
        editable: false,
      },
      product_fee_paid_upfront: {
        order: 7,
        label: "Is product fee to be paid up front",
        format: "yesno",
        editable: false,
      },
      preferred_payment_date: {
        order: 8,
        label: "What is your preferred payment date of the month",
        format: "number",
      },
    },
  },
  remortgage: {
    order: 8,
    fieldset: "Remortgage Details",
    fields: {
      original_purchase_price: {
        order: 0,
        label: "Original purchase price",
        format: "currency",
      },
      lender_name: {
        order: 1,
        label: "Lender's name",
      },
      mortgage_account_number: {
        order: 2,
        label: "Mortgage account number",
      },
      outstanding_mortgage_balance: {
        order: 3,
        label: "Outstanding mortgage amount",
        format: "currency",
      },
      details_of_improvements: {
        order: 4,
        label: "Details of any improvements",
        required: false,
      },
      subsidence_landslip_cracking: {
        order: 5,
        label:
          "Has your home been damaged by subsidence, heave, landslip, or does it show any signs of cracking or bulging of walls",
        format: "yesno",
      },
      number_of_bedrooms: {
        order: 6,
        label: "Number of bedrooms",
        format: "number",
      },
      number_of_separate_toilets: {
        order: 7,
        label: "Number of separate toilets",
        format: "number",
      },
      number_of_habitable_rooms: {
        order: 8,
        label: "Number of habitable rooms",
        format: "number",
      },
      number_of_bathrooms: {
        order: 9,
        label: "Number of bathrooms",
        format: "number",
      },
      number_of_living_rooms: {
        order: 10,
        label: "Number of living rooms",
        format: "number",
      },
      property_central_heating: {
        order: 11,
        label: "Does the property have central heating",
        format: "yesno",
      },
      property_central_heading_type: {
        order: 12,
        label: "Central heating type",
      },
      property_conservatory: {
        order: 13,
        label: "Does the property have a conservatory",
        format: "yesno",
      },
      number_of_cars_garaged: {
        order: 14,
        label: "How many cars can be garaged",
        format: "number",
      },
      off_road_parking: {
        order: 15,
        label: "Off-road parking",
        format: "yesno",
      },
      garden: {
        order: 16,
        label: "Does the property have a garden",
        format: "yesno",
      },
      road_charge_liability: {
        order: 17,
        label: "Road charge liability",
        format: "yesno",
      },
      other_secured_lending_on_property: {
        order: 18,
        label: "Is there any other secured lending on the property",
        format: "yesno",
      },
      reason_for_remortgage: {
        order: 19,
        label: "Reason for remortgage",
      },
    },
  },
  declarations: {
    order: 9,
    fieldset: "Declarations",
    fields: {
      arrears_or_repossession: {
        order: 0,
        label:
          "Has any applicant been in arrears in the last six years with any borrowing or ever had a property repossessed",
        format: "yesno",
      },
      ccjs_or_defaults: {
        order: 1,
        label:
          "Has any applicant had a County Court Judgment (CCJ) or default registered against them in the last 6 years",
        format: "yesno",
      },
      bankrupt: {
        order: 2,
        label:
          "Has any applicant been declared bankrupt, entered into an IVA or debt relief order in the last six years",
        format: "yesno",
      },
    },
  },
  insurance: {
    order: 10,
    fieldset: "Insurance",
    fields: {
      require_quote: {
        order: 0,
        label: "Would you like an insurance quote from the lender?",
        format: "yesno",
      },
    },
  },
};

export const getDefaultsFromFieldsetFields = (
  fieldsetFields: ProductRemortgageApplicationFieldsType
): {
  [K in keyof ProductRemortgageApplicationFieldsType]: string;
} => {
  return Object.keys(fieldsetFields).reduce((acc, key) => {
    acc[key as keyof ProductRemortgageApplicationFieldsType] = "";
    return acc;
  }, {} as { [K in keyof ProductRemortgageApplicationFieldsType]: string });
};

const sortFields = (
  key: string,
  fields: CommonObjectType,
  fieldsWithValues: ProductRemortgageApplicationFieldsType
) =>
  Object.entries(fields)
    .sort(([, a], [, b]) => a.order - b.order)
    .reduce((acc: CommonObjectType, [fieldKey]) => {
      acc[fieldKey] = fieldsWithValues[fieldKey];
      return acc;
    }, {});

export const transformApplicationFromStore = (
  applicationData: CommonObjectType | null = null
): CommonObjectType => {
  if (!applicationData) {
    return {};
  }

  const applicationDataSortedFieldsets = Object.fromEntries(
    Object.entries(applicationData)
      .filter(([fieldset]) => APPLICATION_DATA_MAP?.[fieldset])
      .sort(
        ([fieldsetA], [fieldsetB]) =>
          APPLICATION_DATA_MAP[fieldsetA].order -
          APPLICATION_DATA_MAP[fieldsetB].order
      )
  );

  const applicationDataSortedFieldsetsAndFields: CommonObjectType = {};

  for (const [key] of Object.entries(APPLICATION_DATA_MAP)) {
    const fields = APPLICATION_DATA_MAP[key].fields;

    if (Array.isArray(applicationDataSortedFieldsets[key])) {
      const nestedArrayFieldset = applicationDataSortedFieldsets[key].map(
        (nestedData: CommonObjectType) =>
          sortFields(key, { ...nestedData, ...fields }, nestedData)
      );
      applicationDataSortedFieldsetsAndFields[key] = nestedArrayFieldset;
    } else {
      applicationDataSortedFieldsetsAndFields[key] = sortFields(
        key,
        {
          ...applicationDataSortedFieldsets[key],
          ...fields,
        },
        applicationDataSortedFieldsets[key]
      );
    }
  }

  return applicationDataSortedFieldsetsAndFields;
};

const createNestedSchema = (fields: CommonObjectType) =>
  Object.entries(fields).reduce((acc, [key, value]) => {
    // const fieldConfig = APPLICATION_DATA_MAP[key].fields[fieldKey];
    // const requiredField =
    //   "required" in fieldConfig && !fieldConfig.required ? false : true;

    // if (requiredField) {
    //   obj[fieldKey] = yup.string().required(VALIDATION_REQUIRED);
    // }

    // If temp disabling validation, comment above and uncomment this line:
    // (TO TEST)
    acc[key] = yup.string().nullable();
    return acc;
  }, {} as CommonObjectType);

const createObjectSchema = (fields: CommonObjectType) =>
  yup.object().shape(createNestedSchema(fields));

export const transformApplicationDataForValidation = (
  applicationData: CommonObjectType | null = null
) => {
  if (!applicationData) {
    return undefined;
  }

  const schemaObj: CommonObjectType = {};

  for (const [key, fields] of Object.entries(applicationData)) {
    schemaObj[key] = Array.isArray(fields)
      ? yup.array().of(createObjectSchema(fields[0]))
      : createObjectSchema(fields);
  }

  const schema = yup.object(schemaObj);
  return yupResolver(schema);
};
