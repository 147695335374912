import AccountProfile from "pages/Account/AccountProfile";
import EquityTimeline from "pages/EquityTimeline";
import ProductRemortgage from "pages/ProductRemortgage";
import { RouteType } from "routes/types";
import { APP_URLS } from "settings";

const AUTHENTICATED_VERIFIED_ROUTES: RouteType[] = [
  {
    path: APP_URLS.ACCOUNT.ACCOUNT_PROFILE,
    component: AccountProfile,
  },
  {
    path: APP_URLS.EQUITY_TIMELINE,
    component: EquityTimeline,
  },
  {
    path: APP_URLS.PRODUCT_PAGES.REMORTGAGE.MAIN,
    component: ProductRemortgage,
  },
];

export default AUTHENTICATED_VERIFIED_ROUTES;
