import FormInputError from "components/Form/Input/Error";
import FormInputLabel from "components/Form/Input/Label";
import { FormInputProps } from "components/Form/Input/types";
import { classNames } from "utils/helpers";

export default function FormInputPercent({
  id,
  label,
  error,
  disabled = false,
  asText = false,
  register,
}: FormInputProps) {
  let inputStyles =
    "block w-full appearance-none rounded-none rounded-l-md border border-gray-300 px-3 py-2 text-base placeholder-gray-400 focus:border-brand-orange-medium focus:outline-none focus:ring-brand-orange-medium";

  const inputProps =
    id && register
      ? {
          ...register(id, {
            required: true,
          }),
        }
      : {};

  if (asText) {
    disabled = true;
    inputStyles = `${inputStyles} disabled:border-none disabled:bg-white disabled:p-0 !w-12`;
  }

  return (
    <div>
      <FormInputLabel id={id} label={label} />
      <div className="mt-1 sm:col-span-2">
        <div className={`flex rounded-md ${asText && "-mt-1"}`}>
          <input
            type="number"
            id={id}
            name={id}
            required
            className={inputStyles}
            disabled={disabled}
            min="0"
            placeholder={asText ? "-" : ""}
            {...inputProps}
          />
          <span
            className={classNames(
              asText
                ? "pl-1 pr-0"
                : "border border-l-0 border-gray-300 bg-gray-50 text-gray-500",
              "inline-flex items-center rounded-r-md px-3 text-base"
            )}
          >
            %
          </span>
        </div>
        <FormInputError error={error} />
      </div>
    </div>
  );
}
