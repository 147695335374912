import * as yup from "yup";

import { FormInputProps } from "./types";

type FormInputFileButtonProps = FormInputProps & {
  formats?: string;
  maxUploadSize?: number;
};

export const DEFAULT_MAX_FILE_UPLOAD_SIZE_MB = 15;

const getMBfromBytes = (bytes: number) => bytes / 1024 / 1024;

const validateFileSize = (files?: FileList): boolean => {
  let valid = true;
  if (files) {
    const fileSizes = Array.from(Object.values(files), (file) =>
      getMBfromBytes(file.size)
    );
    if (fileSizes.some((size) => size > DEFAULT_MAX_FILE_UPLOAD_SIZE_MB)) {
      valid = false;
    }
  }
  return valid;
};

const validateMultipleFiles = (files?: FileList): boolean => {
  let valid = true;
  if (files) {
    if (files.length > 3 || files.length < 3) {
      valid = false;
    }
  }
  return valid;
};

const validateFileGiven = (files?: FileList): boolean =>
  files ? files.length === 1 : false;

const validateMin1FileGiven = (files?: FileList): boolean =>
  files ? files.length >= 1 : false;

export const validateSingleFile = (fileType: string) =>
  yup
    .mixed()
    .required(`Please upload a valid ${fileType}`)
    .test(
      "validateFileGiven",
      `Please upload a valid ${fileType}`,
      validateFileGiven
    )
    .test(
      "validateFileSize",
      `Maximum file size for ${fileType} upload is ${DEFAULT_MAX_FILE_UPLOAD_SIZE_MB}MB`,
      validateFileSize
    );

export const validateMin1File = (
  fileType: string,
  requestedFileCount: number
) =>
  yup
    .mixed()
    .required(`Please upload a valid ${fileType}`)
    .test(
      "validateMultipleFiles",
      `Please upload your latest ${requestedFileCount} months ${fileType}`,
      validateMin1FileGiven
    )
    .test(
      "validateFileSize",
      `Maximum file size for ${fileType} upload is ${DEFAULT_MAX_FILE_UPLOAD_SIZE_MB}MB`,
      validateFileSize
    );

export const validateMultiFile = (
  fileType: string,
  requiredFileCount: number
) =>
  yup
    .mixed()
    .required(
      `Please upload your latest ${requiredFileCount} months ${fileType}`
    )
    .test(
      "validateMultipleFiles",
      `Please upload your latest ${requiredFileCount} months ${fileType}`,
      validateMultipleFiles
    )
    .test(
      "validateFileSize",
      `Maximum file size for each ${fileType} upload is ${DEFAULT_MAX_FILE_UPLOAD_SIZE_MB}MB`,
      validateFileSize
    );

export default function FormInputFileButton({
  id,
  label,
  onChange,
  formats = "",
  register,
}: FormInputFileButtonProps) {
  let inputProps = {};

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
  };

  if (id && register) {
    inputProps = {
      ...inputProps,
      ...register(id, {
        required: true,
        onChange: handleOnChange,
      }),
    };
  }

  return (
    <div className="self-end">
      <label htmlFor={id} className="cursor-pointer">
        <div>
          <p>
            <span className="justify-center rounded-md border border-brand-green-dark bg-white px-7 py-1.5 text-base font-semibold shadow-md hover:bg-gray-50">
              {label}
            </span>
            <input
              id={id}
              name={id}
              type="file"
              className="sr-only"
              accept={formats}
              {...inputProps}
            />
          </p>
        </div>
      </label>
    </div>
  );
}
