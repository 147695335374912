import * as Sentry from "@sentry/react";
import IconContinueWithGoogle from "components/Image/Icons/ContinueWithGoogle";
import { useEffect, useState } from "react";

import { trackEvent } from "analytics";
import { SERVICES } from "settings";

declare global {
  interface Window {
    google: any;
    gapi: any;
  }
}

const SCOPES = "https://www.googleapis.com/auth/gmail.readonly";
const DISCOVERY_DOC =
  "https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest";

export default function ConnectGmail({
  handleReset,
  handleError,
  handleSuccess,
}: {
  handleReset: Function;
  handleSuccess: Function;
  handleError: Function;
}) {
  const [tokenClient, setTokenClient] = useState<any | null>(null);
  const [googleAPILoaded, setGoogleAPILoaded] = useState<boolean>(false);
  const [googleClientLoaded, setGoogleClientLoaded] = useState<boolean>(false);

  useEffect(() => {
    const scriptApi = document.createElement("script");
    scriptApi.src = "https://apis.google.com/js/api.js";
    scriptApi.async = true;
    scriptApi.defer = true;
    scriptApi.addEventListener("load", () => {
      handleGoogleAPILoaded();
    });
    document.body.appendChild(scriptApi);

    const scriptClient = document.createElement("script");
    scriptClient.src = "https://accounts.google.com/gsi/client";
    scriptClient.async = true;
    scriptClient.defer = true;
    scriptClient.addEventListener("load", () => {
      handleGoogleClientLoaded();
    });
    document.body.appendChild(scriptClient);

    return () => {
      document.body.removeChild(scriptApi);
      document.body.removeChild(scriptClient);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoogleAPILoaded = () => {
    window.gapi.load("client", initializeGapiClient);
  };

  const initializeGapiClient = async () => {
    await window.gapi.client.init({
      apiKey: SERVICES.GOOGLE.EMAIL_DOC_PARSER.API_KEY,
      discoveryDocs: [DISCOVERY_DOC],
    });
    setGoogleAPILoaded(true);
  };

  const handleGoogleClientLoaded = () => {
    const _tokenClient = window.google.accounts.oauth2.initCodeClient({
      client_id: SERVICES.GOOGLE.EMAIL_DOC_PARSER.CLIENT_ID,
      scope: SCOPES,
      ux_mode: "popup",
      callback: "",
    });
    setTokenClient(_tokenClient);
    setGoogleClientLoaded(true);
  };

  const triggerGoogleConnect = () => {
    trackEvent("Triggered Google auth");
    handleReset();

    tokenClient.callback = async (resp: any) => {
      if (resp.error !== undefined) {
        trackEvent("Google auth fail: cancelled");
        handleError(true);
        Sentry.captureException(resp.error);
        return;
      }

      trackEvent("Google auth success");

      handleSuccess({
        gapi: {
          auth: {
            code: resp?.code,
            scope: resp?.scope,
          },
        },
      });
    };

    tokenClient.requestCode();

    // TODO: If already authed, code from backend? Dont show pop up?
  };

  if (googleAPILoaded && googleClientLoaded) {
    return (
      <div className="mb-3">
        <button className="mx-auto mb-1 block" onClick={triggerGoogleConnect}>
          <IconContinueWithGoogle />
        </button>
      </div>
    );
  }

  return <></>;
}
