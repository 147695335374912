import { useMutation } from "@apollo/client";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import * as yup from "yup";

import { trackEvent } from "analytics";
import { MUTATION_UPDATE_EC_PRODUCT_REMORTGAGE } from "api/mutations/products";
import CTAButton from "components/CTA/Button";
import FormInputRadioGroup from "components/Form/Input/RadioGroup";
import FormInputText from "components/Form/Input/Text";
import { getRootChildRoute } from "routes/utils";
import { APP_URLS } from "settings";
import { PRODUCT_STEPS, useProductsState } from "store/Products";
import { ProductRemortgageType } from "store/Products/types";
import { VALIDATION_REQUIRED } from "utils/validation";
import ProductHeader from "./ProductHeader";

type ManualQsType = {
  permanently_employed: string;
  dependent_adults: string;
  dependent_children: string;
};

const manualQsSchema = yup.object({
  permanently_employed: yup.string().required(VALIDATION_REQUIRED),
  dependent_adults: yup.string().required(VALIDATION_REQUIRED),
  dependent_children: yup.string().required(VALIDATION_REQUIRED),
});

export function ManualQuestions({
  productRemortgage,
}: {
  productRemortgage: ProductRemortgageType;
}) {
  const navigate = useNavigate();

  const [questionsSubmitted, setQuestionsSubmitted] = useState<boolean>(false);

  const setRemortgageProgress = useProductsState(
    (state) => state.setRemortgageProgress
  );

  const [submitOutstandingQs, { data }] = useMutation(
    MUTATION_UPDATE_EC_PRODUCT_REMORTGAGE
  );

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ManualQsType>({
    resolver: yupResolver(manualQsSchema),
    defaultValues: {
      permanently_employed: "",
      dependent_adults: "",
      dependent_children: "",
    },
  });

  useEffect(() => {
    trackEvent("Manual questions");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setRemortgageProgress({
        ...productRemortgage,
        ...{
          manualData: data?.response?.product?.manualData,
        },
      });
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSubmit = (data: ManualQsType) => {
    setQuestionsSubmitted(true);
    window.scrollTo(0, 0);

    if (data.permanently_employed.toLowerCase() === "no") {
      return navigate(
        getRootChildRoute(
          APP_URLS.PRODUCT_PAGES.REMORTGAGE,
          PRODUCT_STEPS.REMORTGAGE.TALK_TO_US
        )
      );
    }

    submitOutstandingQs({
      variables: {
        input: {
          id: productRemortgage.id,
          manualData: data,
        },
      },
    });
  };

  return (
    <>
      <ProductHeader title="Finding you the best deal" />

      <div className="mx-4 rounded-lg bg-gray-100 p-6 md:mx-6">
        {questionsSubmitted ? (
          <>
            <CheckCircleIcon className="bg-green mx-auto h-8 w-8" />
            <p className="mb-t mx-4 text-center">Thank's for your answers!</p>
          </>
        ) : (
          <form
            className="mx-auto flex max-w-md flex-col justify-center space-y-4"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <p className="text-center text-lg">
              While we search, help us narrow down the perfect options for you:
            </p>

            <div className="space-y-2 rounded-lg bg-white px-4 py-3 shadow">
              <span className="font-semibold">
                Are you in permanent employment? (This means you're not
                self-employed or on a fixed-term contract)
              </span>
              <FormInputRadioGroup
                id="permanently_employed"
                options={["Yes", "No"]}
                error={errors?.permanently_employed?.message}
                control={control}
                fiftyfifty
              />
            </div>

            <div className="space-y-2 rounded-lg bg-white px-4 py-3 shadow">
              <span className="font-semibold">
                How many adults depend on you financially?
              </span>
              <FormInputText
                id="dependent_adults"
                type="number"
                register={register}
                error={errors?.dependent_adults?.message}
              />
            </div>

            <div className="space-y-2 rounded-lg bg-white px-4 py-3 shadow">
              <span className="font-semibold">
                How many children depend on you financially?
              </span>
              <FormInputText
                id="dependent_children"
                type="number"
                register={register}
                error={errors?.dependent_children?.message}
              />
            </div>

            <div>
              <CTAButton full type="submit" label="Submit >" />
            </div>
          </form>
        )}
      </div>
    </>
  );
}
