import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { trackPage } from "analytics";
import ActionCTA from "components/ActionCTA";
import CTAButton from "components/CTA/Button";
import { getRootChildRoute } from "routes/utils";
import { APP_URLS, IS_TEST_ENV } from "settings";
import { PRODUCT_STEPS } from "store/Products";
import { useUserStore } from "store/User";
import { asCurrency } from "utils/currency";
import ProductHeader from "./components/ProductHeader";
import TriggerGetStartedDocs from "./components/TriggerGetStartedDocs";

export default function ProductRemortgageMain() {
  const navigate = useNavigate();
  const equityCheck = useUserStore((state) => state.equityCheck);

  useEffect(() => {
    trackPage("Remortgage");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!equityCheck) {
      return navigate(APP_URLS.DASHBOARD);
    }
    if (!equityCheck.productAutoRemortgage || !equityCheck.live) {
      return navigate(APP_URLS.DASHBOARD);
    }
  }, [equityCheck, navigate]);

  const naviateToUploadDocs = () =>
    navigate(
      getRootChildRoute(
        APP_URLS.PRODUCT_PAGES.REMORTGAGE,
        PRODUCT_STEPS.REMORTGAGE.UPLOAD_DOCS
      )
    );

  if (!equityCheck) {
    return <></>;
  }

  return (
    <>
      <ProductHeader title="Based on your home equity and current payments, remortgaging could save you">
        <>
          <p className="mt-4 text-6xl">
            £
            {equityCheck.productAutoRemortgage?.potentialYearlySavings &&
            equityCheck.productAutoRemortgage?.potentialYearlySavings > 0
              ? asCurrency({
                  value:
                    equityCheck.productAutoRemortgage?.potentialYearlySavings,
                })
              : 0}
          </p>
          <p className="text-lg">per year</p>
        </>
      </ProductHeader>

      <div className="mx-4 rounded-lg bg-gray-100 py-6 text-center md:mx-6">
        <div className="mx-auto flex w-full max-w-sm flex-col space-y-6 px-4 sm:px-0">
          <ActionCTA name="RemortgageCurrent" bookACall={false} small>
            <p className="text-center">
              How much you're paying per month
              <br />
              <span className="text-3xl font-medium">
                £
                {asCurrency({
                  value: equityCheck.live?.totalMonthlyMortgagePayment,
                  rounding: false,
                })}
              </span>
            </p>
          </ActionCTA>

          <ActionCTA name="RemortgagePotential" bookACall={false} small>
            <p className="text-center">
              How much you could be paying per month
              <br />
              <span className="text-3xl font-medium">
                £
                {asCurrency({
                  value:
                    equityCheck.productAutoRemortgage?.cheapestMonthlyPayment,
                  rounding: false,
                })}
              </span>
            </p>
          </ActionCTA>

          <p className="text-lg font-semibold">
            We can help you remortgage in under 5 minutes
          </p>

          {IS_TEST_ENV ? (
            <TriggerGetStartedDocs />
          ) : (
            <CTAButton label="Get Started >" onClick={naviateToUploadDocs} />
          )}
        </div>
      </div>
    </>
  );
}
