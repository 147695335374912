import { gql } from "@apollo/client";

export const QUERY_EC_PRODUCT_REMORTGAGE = gql`
  query ECProductRemortgage {
    response: ecProductRemortgage {
      id
      documents {
        mortgageOffer
        payslips
        passport
        bankStatements
      }
      equityCheck {
        productAutoRemortgage {
          totalOptions
          bestOptions
          cheapestMonthlyPayment
          potentialMonthlySavings
          potentialYearlySavings
          dealsReady
        }
        customer {
          linkedData {
            mortgageOffer {
              aprc
              endDate
              fixedInterestRate
              lender
              lenderImg
              remainingTerm
              fixedTermMonthlyPayment
            }
          }
        }
      }
    }
  }
`;

export const QUERY_EC_PRODUCT_REMORTGAGE_REVIEW_READY = gql`
  query ECProductRemortgage {
    response: ecProductRemortgage {
      id
      reviewReady
    }
  }
`;
