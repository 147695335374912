import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from "@apollo/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import ReactDOM from "react-dom/client";

import App from "App";
import "css/App.css";

import { initAnalytics } from "analytics";
import reportWebVitals from "reportWebVitals";
import { COOKIES } from "settings";
import { getCookie } from "utils/helpers";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: 0.1,
  });
}

initAnalytics();

const apolloLink = createUploadLink({
  uri: process.env.REACT_APP_API,
  credentials: "include",
  headers: {
    "X-CSRFToken": getCookie(COOKIES.CSRF) || "",
  },
});

const client = new ApolloClient({
  link: apolloLink as unknown as ApolloLink,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
  // </React.StrictMode>
);

reportWebVitals();
