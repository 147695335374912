import { useQuery } from "@apollo/client";
import { QUERY_EC_PRODUCT_REMORTGAGE } from "api/queries/products";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { trackEvent } from "analytics";
import CTAButton from "components/CTA/Button";
import { getRootChildRoute } from "routes/utils";
import { APP_URLS, WEB_URLS } from "settings";
import { PRODUCT_STEPS, useProductsState } from "store/Products";
import { ProductRemortgageType } from "store/Products/types";
import { useUserStore } from "store/User";
import { MortgageOptionType } from "ts/types/products";
import { asCurrency } from "utils/currency";
import ProductHeader from "./ProductHeader";

const POLL_INTERVAL = 6000;
const MAX_POLL_TIMEOUT = 210000; // 3.5 minutes

export function DealsListing({
  productRemortgage,
}: {
  productRemortgage: ProductRemortgageType;
}) {
  const navigate = useNavigate();

  const [pollCounter, setPollCounter] = useState<number>(0);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingCopy, setLoadingCopy] = useState(
    "Searching the market for mortgages that meet your needs..."
  );

  const { equityCheck, userLinkedData, setUserLinkedData } = useUserStore(
    (state) => state
  );
  const setRemortgageProgress = useProductsState(
    (state) => state.setRemortgageProgress
  );

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
    stopPolling,
  } = useQuery(QUERY_EC_PRODUCT_REMORTGAGE, {
    notifyOnNetworkStatusChange: true,
    pollInterval: POLL_INTERVAL,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    trackEvent("Loading deals");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pollCounter >= MAX_POLL_TIMEOUT) {
      trackEvent("Deals timeout");

      stopPolling();

      return navigate(
        getRootChildRoute(
          APP_URLS.PRODUCT_PAGES.REMORTGAGE,
          PRODUCT_STEPS.REMORTGAGE.TALK_TO_US
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollCounter]);

  useEffect(() => {
    if (queryLoading) {
      return;
    }
    if (queryData?.response) {
      setPollCounter(pollCounter + POLL_INTERVAL);

      const dealsReady =
        queryData.response?.equityCheck?.productAutoRemortgage?.dealsReady;

      if (!dealsReady) {
        return;
      }

      // Deals ready, stop polling for result
      stopPolling();
      setPollCounter(0);
      setLoadingProgress(98);

      trackEvent("Deals loaded");

      setRemortgageProgress({
        ...productRemortgage,
        ...{
          documents: queryData.response?.documents,
          options: queryData.response?.equityCheck?.productAutoRemortgage,
          step: PRODUCT_STEPS.REMORTGAGE.DEALS,
        },
      });

      setUserLinkedData(queryData.response?.equityCheck?.customer?.linkedData);
    } else {
      return navigate(APP_URLS.DASHBOARD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData, queryLoading]);

  useEffect(() => {
    if (queryError) {
      return navigate(APP_URLS.DASHBOARD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryError]);

  useEffect(() => {
    if (loadingProgress === 99) {
      setLoadingCopy("Complete!");
      setTimeout(() => {
        setLoadingProgress(100);
      }, 1000);
      return;
    }
    if (loadingProgress >= 100) {
      return;
    }
    if (loadingProgress >= 33) {
      setLoadingCopy(
        "We've found a number of deals, we'll now check your eligibility to ensure we can pre-qualify you..."
      );
    }
    if (loadingProgress >= 66) {
      setLoadingCopy(
        "Almost there, we're now filtering the results to give you the top 3 options..."
      );
    }

    const intervalId = setInterval(() => {
      setLoadingProgress((prevTimeLeft: number) => prevTimeLeft + 0.5);
    }, 500);

    return () => clearInterval(intervalId);
  }, [loadingProgress, setLoadingProgress]);

  const selectMortgageOption = (mortgageOption: MortgageOptionType) => {
    setRemortgageProgress({
      ...productRemortgage,
      ...{
        step: PRODUCT_STEPS.REMORTGAGE.ILLUSTRATION,
        selectedMortgage: mortgageOption,
      },
    });
    return navigate(
      getRootChildRoute(
        APP_URLS.PRODUCT_PAGES.REMORTGAGE,
        PRODUCT_STEPS.REMORTGAGE.ILLUSTRATION
      )
    );
  };

  const renderLenderLogo = (lenderImg: string, alt: string) => {
    const _alt = alt === "" ? "Lender" : alt;
    return (
      <div className="relative flex h-[65px] items-center justify-center px-4 py-3 md:h-auto">
        <img
          src={`${WEB_URLS.CDN.IMAGES}lender/${lenderImg}`}
          alt={_alt}
          className="z-10 h-full max-h-[50px] w-full max-w-[125px] bg-white object-contain"
          onError={(event) => {
            (event.target as HTMLImageElement).style.display = "none";
          }}
        />
        <span className="absolute z-0 px-4 py-3 text-center text-lg">
          {_alt}
        </span>
      </div>
    );
  };

  const renderOptionStat = (
    stat: string | number,
    title?: string,
    highlight: boolean = false
  ) => (
    <div className="block items-center px-4 py-3 lg:flex">
      <div className="flex items-center justify-between lg:block">
        {title && (
          <span
            className={`text-base text-brand-copy-light ${
              highlight && "font-semibold"
            }`}
          >
            {title}
          </span>
        )}
        <span
          className={`flex text-2xl ${
            highlight && "font-semibold text-brand-orange-medium"
          }`}
        >
          {stat}
        </span>
      </div>
    </div>
  );

  const renderOption = (termOption: string) => {
    if (
      equityCheck &&
      userLinkedData?.mortgageOffer &&
      productRemortgage?.options?.bestOptions &&
      termOption in productRemortgage?.options?.bestOptions
    ) {
      const mortgageOption: MortgageOptionType =
        productRemortgage.options.bestOptions[termOption];

      const initialMonthlyPayment = asCurrency({
        value: mortgageOption.initial_monthly_payment,
        rounding: false,
      });

      // TODO: Fixed term period only for now, what about svr term payment?
      const calcMonthlySavings =
        equityCheck.live.totalMonthlyMortgagePayment -
        mortgageOption.initial_monthly_payment;
      const monthlySavings =
        calcMonthlySavings <= 0
          ? 0
          : asCurrency({
              value: calcMonthlySavings,
              rounding: false,
            });

      return (
        <div className="p-6">
          <h3 className="text-2xl font-semibold leading-6">
            Best {mortgageOption.fixed_term_years} year fixed
          </h3>
          <div className="mt-3 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow lg:grid-cols-7 lg:divide-x lg:divide-y-0">
            {renderLenderLogo(mortgageOption.lender_img, mortgageOption.lender)}
            {renderOptionStat(`£${initialMonthlyPayment}`, "Monthly payment")}
            {renderOptionStat(`${mortgageOption.initial_rate}%`, "Rate")}
            {mortgageOption.apr
              ? renderOptionStat(`${mortgageOption.apr}%`, "APRC")
              : renderOptionStat(`${mortgageOption.svr}%`, "SVR")}
            {renderOptionStat(
              `${mortgageOption.full_term_years} years`,
              "Term"
            )}
            {renderOptionStat(`£${monthlySavings}`, "Monthly savings", true)}
            <div className="mr-3 flex items-center justify-end py-3 lg:mr-0 lg:justify-center">
              {productRemortgage?.selectedMortgage &&
              productRemortgage?.selectedMortgage.fixed_term_years ===
                mortgageOption.fixed_term_years ? (
                <div className="flex flex-col">
                  <CheckCircleIcon className="bg-green mx-auto h-8 w-8" />
                  <span>Selected!</span>
                </div>
              ) : (
                <CTAButton
                  label="Select >"
                  onClick={() => selectMortgageOption(mortgageOption)}
                />
              )}
            </div>
          </div>
        </div>
      );
    }
    return <></>;
  };

  const renderCurrentDeal = () => {
    if (!equityCheck) {
      return <></>;
    }

    if (!userLinkedData?.mortgageOffer) {
      return <></>;
    }

    // TODO: Fixed term period only for now, what about svr term payment?
    // OR equityCheck.live.totalMonthlyMortgagePayment ?
    const currentMonthlyPayment = asCurrency({
      value: equityCheck.live.totalMonthlyMortgagePayment,
      rounding: false,
    });

    return (
      <div className="p-6">
        <h3 className="text-2xl font-semibold leading-6">Your current deal</h3>
        <div className="mt-3 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow lg:grid-cols-7 lg:divide-x lg:divide-y-0">
          {renderLenderLogo(
            userLinkedData.mortgageOffer.lenderImg,
            userLinkedData.mortgageOffer.lender
          )}
          {renderOptionStat(`£${currentMonthlyPayment}`, "Monthly payment")}
          {userLinkedData.mortgageOffer.fixedInterestRate &&
            renderOptionStat(
              `${userLinkedData.mortgageOffer.fixedInterestRate}%`,
              "Rate"
            )}
          {userLinkedData.mortgageOffer.aprc &&
            renderOptionStat(`${userLinkedData.mortgageOffer.aprc}%`, "APRC")}
          {userLinkedData.mortgageOffer.remainingTerm &&
            renderOptionStat(
              `${userLinkedData.mortgageOffer.remainingTerm} years`,
              "Remaining Term"
            )}
        </div>
      </div>
    );
  };

  if (
    queryData?.response?.equityCheck?.productAutoRemortgage?.dealsReady &&
    userLinkedData?.mortgageOffer &&
    productRemortgage?.options &&
    !queryLoading &&
    loadingProgress >= 100
  ) {
    const totalDeals = productRemortgage?.options?.totalOptions
      ? productRemortgage.options.totalOptions
      : "many";

    return (
      <>
        <ProductHeader title={`We found ${totalDeals} Remortgage deals.`}>
          <p className="mb-4 mt-1 text-2xl">
            Here are the best options for you:
          </p>
        </ProductHeader>

        <div className="mx-0 rounded-lg bg-gray-100 py-6 md:mx-6">
          {renderCurrentDeal()}
          {renderOption("2_YEAR")}
          {renderOption("3_YEAR")}
          {renderOption("5_YEAR")}
        </div>
      </>
    );
  }

  return (
    <>
      <ProductHeader title="Finding you the best deal" />

      <div className="mx-4  p-6 md:mx-6">
        <div className="mt-1">
          <div className="relative">
            <div className="mb-4 flex h-4 overflow-hidden rounded-lg bg-brand-green-medium bg-opacity-50 text-xs">
              <div
                style={{ width: `${loadingProgress}%` }}
                className="flex w-0 flex-col justify-center whitespace-nowrap bg-brand-green-dark text-center text-white shadow-none transition-[width] duration-500 ease-in-out"
              ></div>
            </div>
          </div>
        </div>

        <p className="text-center text-lg">{loadingCopy}</p>
      </div>
    </>
  );
}
