import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";

import { QUERY_AUTHENTICATED_USER } from "api/queries/account";
import EventListeners from "components/EventListeners";
import Footer from "components/Footer";
import Loading from "components/Loading";
import Notifications from "components/Notifications";
import PageContainer from "components/Page/Container";
import PageDialogScreen from "components/Page/DialogScreen";
import Page404 from "pages/Error/404";
import AUTHENTICATED_ROUTES from "routes/authenticated";
import AUTHENTICATED_VERIFIED_ROUTES from "routes/authenticatedVerified";
import RouteRequireAuth from "routes/components/RouteRequireAuth";
import RouteRequireAuthVerified from "routes/components/RouteRequireAuthVerified";
import RouteUnauthenticated from "routes/components/RouteUnauthenticated";
import UNAUTHENTICATED_ROUTES from "routes/unauthenticated";
import { AHAUZ_PHONE_NUMBER } from "settings";
import { useUserStore } from "store/User";

export default function App() {
  const setUser = useUserStore((state) => state.setUser);

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(QUERY_AUTHENTICATED_USER, {
    fetchPolicy: "no-cache",
  });

  /**
   * Check API response and set user state based on authenticated result
   */
  useEffect(() => {
    queryData?.customer && setUser(queryData.customer);
  }, [queryData, setUser]);

  /**
   * Restore scroll window position on route change
   * @param props
   * @returns Route
   */
  const ScrollToTop = (props: any) => {
    const location = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

    return <>{props.children}</>;
  };

  if (queryLoading) {
    return (
      <PageDialogScreen logo={false}>
        <Loading large />
      </PageDialogScreen>
    );
  }

  if (queryError) {
    return (
      <PageDialogScreen>
        <PageContainer>
          <>
            <p className="mb-2 text-center text-lg font-medium">
              Sorry, we're experiencing issues
            </p>
            <p className="text-center">
              Please try again shortly, or contact our Customer Support team on{" "}
              {AHAUZ_PHONE_NUMBER}.
            </p>
          </>
        </PageContainer>
      </PageDialogScreen>
    );
  }

  const isAuthenticated = queryData?.customer;
  const isVerified = queryData?.customer?.isVerified;

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route
              element={
                <RouteRequireAuthVerified
                  authenticated={isAuthenticated}
                  verified={isVerified}
                />
              }
            >
              {AUTHENTICATED_VERIFIED_ROUTES.map(
                (route, i) =>
                  !(route?.disabled ?? false) && (
                    <Route
                      key={i}
                      path={route.path}
                      element={<route.component />}
                    />
                  )
              )}
            </Route>
            <Route
              element={<RouteRequireAuth authenticated={isAuthenticated} />}
            >
              {AUTHENTICATED_ROUTES.map(
                (route, i) =>
                  !(route?.disabled ?? false) && (
                    <Route
                      key={i}
                      path={route.path}
                      element={<route.component />}
                    />
                  )
              )}
            </Route>
            <Route
              element={<RouteUnauthenticated authenticated={isAuthenticated} />}
            >
              {UNAUTHENTICATED_ROUTES.map(
                (route, i) =>
                  !(route?.disabled ?? false) && (
                    <Route
                      key={i}
                      path={route.path}
                      element={<route.component />}
                    />
                  )
              )}
            </Route>
            <Route path="*" element={<Page404 />} />
          </Routes>
        </ScrollToTop>
      </Router>
      <Footer />
      <Notifications />
      <EventListeners />
    </>
  );
}
