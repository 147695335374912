import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { MUTATION_UPDATE_EC_PRODUCT_REMORTGAGE } from "api/mutations/products";
import { QUERY_EC_PRODUCT_REMORTGAGE_REVIEW_READY } from "api/queries/products";

import { trackPage } from "analytics";
import AlertError from "components/Alert/Error";
import Loading from "components/Loading";
import { getRootChildRoute } from "routes/utils";
import { APP_URLS } from "settings";
import { PRODUCT_STEPS, useProductsState } from "store/Products";
import { CommonObjectType } from "ts/types";
import ProductHeader from "../components/ProductHeader";
import ReviewEditForm from "../components/ReviewEditForm";

export default function Review() {
  const navigate = useNavigate();

  const [errorPreparingSubmission, setErrorPreparingSubmission] =
    useState<boolean>(false);
  const [applicationSubmitting, setApplicationSubmitting] =
    useState<boolean>(false);
  const [applicationSubmitError, setApplicationSubmitError] =
    useState<boolean>(false);

  const { productRemortgage, setRemortgageProgress } = useProductsState(
    (state) => state
  );

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
    stopPolling,
  } = useQuery(QUERY_EC_PRODUCT_REMORTGAGE_REVIEW_READY, {
    pollInterval: 6000,
    fetchPolicy: "no-cache",
  });

  const [
    prepareApplicationForSubmission,
    { error: prepareSubmitMutationError, data: prepareSubmitMutationData },
  ] = useMutation(MUTATION_UPDATE_EC_PRODUCT_REMORTGAGE);

  const [
    submitApplication,
    { error: submitMutationError, data: submitMutationData },
  ] = useMutation(MUTATION_UPDATE_EC_PRODUCT_REMORTGAGE);

  const timeoutID = setTimeout(() => {
    if (!queryData?.response) {
      return;
    }
    if (!queryData?.response?.reviewReady) {
      stopPolling();
      setErrorPreparingSubmission(true);
    }
  }, 120000);

  useEffect(() => {
    trackPage("Review");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (queryLoading) {
      return;
    }
    if (queryData?.response?.reviewReady) {
      clearTimeout(timeoutID);
      stopPolling();
      prepareApplicationForSubmission({
        variables: {
          input: {
            id: productRemortgage?.id,
            prepareForSubmission: true,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData, queryLoading]);

  useEffect(() => {
    if (queryError) {
      clearTimeout(timeoutID);
      stopPolling();
      setErrorPreparingSubmission(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryError]);

  useEffect(() => {
    if (prepareSubmitMutationError) {
      setErrorPreparingSubmission(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prepareSubmitMutationError]);

  useEffect(() => {
    if (prepareSubmitMutationData?.response) {
      if (
        prepareSubmitMutationData.response.ok &&
        prepareSubmitMutationData.response?.product?.applicationData
      ) {
        setRemortgageProgress({
          ...productRemortgage,
          ...{
            applicationData:
              prepareSubmitMutationData.response.product.applicationData,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prepareSubmitMutationData]);

  useEffect(() => {
    if (submitMutationData?.response) {
      if (submitMutationData.response.ok) {
        setRemortgageProgress({
          ...productRemortgage,
          ...{
            step: PRODUCT_STEPS.REMORTGAGE.SUBMITTED,
            submittedOn: submitMutationData.response.product.submittedOn,
          },
        });
        setTimeout(
          () =>
            navigate(
              getRootChildRoute(
                APP_URLS.PRODUCT_PAGES.REMORTGAGE,
                PRODUCT_STEPS.REMORTGAGE.SUBMITTED
              )
            ),
          2500
        );
      } else {
        setApplicationSubmitting(false);
        setApplicationSubmitError(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitMutationData]);

  useEffect(() => {
    if (submitMutationError) {
      setApplicationSubmitError(true);
      setApplicationSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitMutationError]);

  useEffect(() => {
    // TODO: When we re-intro Illustration
    // if (!productRemortgage?.viewedIllustration) {
    if (!productRemortgage?.selectedMortgage) {
      navigate(
        getRootChildRoute(
          APP_URLS.PRODUCT_PAGES.REMORTGAGE,
          PRODUCT_STEPS.REMORTGAGE.DEALS
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productRemortgage]);

  const renderError = () => (
    <div className="mx-auto w-full max-w-lg">
      <AlertError title="Error submitting your Application">
        <p>
          Sorry, there is an issue submitting your Application to{" "}
          {productRemortgage?.selectedMortgage?.lender}. Please try again or
          contact us.
        </p>
      </AlertError>
    </div>
  );

  const handleSubmitApplication = (data: CommonObjectType) => {
    setApplicationSubmitting(true);
    setApplicationSubmitError(false);

    window.scrollTo(0, 0);

    submitApplication({
      variables: {
        input: {
          id: productRemortgage?.id,
          submit: true,
          applicationData: data,
        },
      },
    });
  };

  if (errorPreparingSubmission) {
    return (
      <>
        <ProductHeader title="Sorry, we've not been able to prepare your full application" />
        <p className="text-md mx-4 mt-4 text-center">
          Please try again later or contact our Customer Service team to discuss
          your options.
        </p>
      </>
    );
  }

  if (productRemortgage?.applicationData) {
    if (applicationSubmitting) {
      return (
        <>
          <ProductHeader
            title={`Submitting your Application to ${productRemortgage?.selectedMortgage?.lender}`}
          />
          <Loading />
        </>
      );
    }

    return (
      <>
        <ProductHeader title="Your application is ready, please review and submit" />

        {applicationSubmitError ? (
          renderError()
        ) : (
          <>
            <div className="mx-4 rounded-lg bg-gray-100 p-6 md:mx-6">
              <ReviewEditForm onSubmit={handleSubmitApplication} />
            </div>

            {/* <button
              className="link mx-auto block"
              onClick={() => navigate(APP_URLS.DASHBOARD)}
            >
              I'm not ready
            </button> */}
          </>
        )}
      </>
    );
  }

  return <Loading padding />;
}
