import {
  STYLE_INPUT,
  STYLE_INPUT_MARGIN_TOP,
} from "components/Form/Input/consts";
import FormInputError from "components/Form/Input/Error";
import FormInputLabel from "components/Form/Input/Label";
import { FormInputProps } from "components/Form/Input/types";

export default function FormInputText({
  id,
  label,
  error,
  disabled = false,
  asText = false,
  type = "text",
  register,
}: FormInputProps) {
  const inputProps =
    id && register ? { ...register(id, { required: true }) } : {};

  let inputStyles = STYLE_INPUT;

  if (asText) {
    disabled = true;
    inputStyles = `${inputStyles} disabled:border-none disabled:bg-white disabled:p-0 disabled:-mt-1`;
  }

  return (
    <div>
      <FormInputLabel id={id} label={label} />
      <div className={STYLE_INPUT_MARGIN_TOP}>
        <input
          id={id}
          name={id}
          type={type}
          required
          className={inputStyles}
          disabled={disabled}
          placeholder={asText ? "-" : ""}
          {...(type === "number" ? { min: 0 } : {})}
          {...inputProps}
        />
        <FormInputError error={error} />
      </div>
    </div>
  );
}
