import { useMutation } from "@apollo/client";
// import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
// import { MouseEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { MUTATION_UPDATE_EC_PRODUCT_REMORTGAGE } from "api/mutations/products";
// import CTAButton from "components/CTA/Button";
import { trackPage } from "analytics";
import Loading from "components/Loading";
import { getRootChildRoute } from "routes/utils";
import { APP_URLS } from "settings";
import { PRODUCT_STEPS, useProductsState } from "store/Products";
import ProductHeader from "../components/ProductHeader";

export default function Illustration() {
  const navigate = useNavigate();

  // const [illustrationDoc, setIllustrationDoc] = useState<string>("");
  // const [illustrationRead, setIllustrationRead] = useState<boolean>(false);

  const productRemortgage = useProductsState(
    (state) => state.productRemortgage
  );
  // const setRemortgageProgress = useProductsState(
  //   (state) => state.setRemortgageProgress
  // );

  const [
    createIllustration,
    { loading: mutationLoading, error: mutationError, data: mutationData },
  ] = useMutation(MUTATION_UPDATE_EC_PRODUCT_REMORTGAGE);

  useEffect(() => {
    trackPage("Illustration");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (productRemortgage?.selectedMortgage) {
      createIllustration({
        variables: {
          input: {
            id: productRemortgage.id,
            selectedMortgageOption: productRemortgage?.selectedMortgage,
          },
        },
      });
    } else {
      navigateToDeals();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productRemortgage]);

  useEffect(() => {
    if (mutationLoading) {
      return;
    }
    if (mutationData?.response) {
      // TODO: When we re-intro Illustration
      // if (
      //   mutationData.response.ok &&
      //   mutationData?.response?.product?.illustration
      // ) {
      //   setIllustrationDoc(mutationData.response.product.illustration);
      // } else {
      //   navigateToDeals();
      // }

      if (mutationData.response.ok) {
        setTimeout(() => {
          return navigate(
            getRootChildRoute(
              APP_URLS.PRODUCT_PAGES.REMORTGAGE,
              PRODUCT_STEPS.REMORTGAGE.REVIEW
            )
          );
        }, 2000);
      } else {
        navigateToDeals();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationData, mutationLoading]);

  useEffect(() => {
    if (mutationError) {
      navigateToDeals();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationError]);

  const navigateToDeals = () =>
    navigate(
      getRootChildRoute(
        APP_URLS.PRODUCT_PAGES.REMORTGAGE,
        PRODUCT_STEPS.REMORTGAGE.DEALS
      )
    );

  return (
    <>
      <ProductHeader title="We're preparing your Application" />
      <Loading />
    </>
  );

  // TODO: When we re-intro Illustration

  // const handleOpenIllustration = (e: MouseEvent) => {
  //   e.preventDefault();
  //   window.open(illustrationDoc, "_blank");
  //   setIllustrationRead(true);
  // };

  // const confirmViewedIllustration = () => {
  //   setRemortgageProgress({
  //     ...productRemortgage,
  //     ...{
  //       step: PRODUCT_STEPS.REMORTGAGE.REVIEW,
  //       illustration: illustrationDoc,
  //       viewedIllustration: true,
  //     },
  //   });
  //   return navigate(
  //     getRootChildRoute(
  //       APP_URLS.PRODUCT_PAGES.REMORTGAGE,
  //       PRODUCT_STEPS.REMORTGAGE.REVIEW
  //     )
  //   );
  // };

  // if (illustrationDoc) {
  //   return (
  //     <>
  //       <ProductHeader title="We're preparing your Application" />

  //       <div className="mx-4 rounded-lg bg-gray-100 py-6 text-center md:mx-6">
  //         <p className="mb-1">
  //           In the meantime, please download and review your Mortgage
  //           Illustration.
  //         </p>

  //         <p className="mb-4">
  //           You can then continue to approve and submit your Application.
  //         </p>

  //         <div className="mb-4 flex justify-center">
  //           <button
  //             onClick={handleOpenIllustration}
  //             className="text-brand-green-dark hover:text-brand-green-dark hover:underline"
  //           >
  //             <div className="mx-auto inline-block flex items-center justify-center rounded-lg bg-white p-3 shadow">
  //               <DocumentTextIcon className="mr-2 h-auto w-10" />
  //               <span>Download Mortgage Illustration</span>
  //             </div>
  //           </button>
  //         </div>
  //         <CTAButton
  //           label="Continue >"
  //           enabled={illustrationRead}
  //           onClick={confirmViewedIllustration}
  //         />
  //       </div>

  //       <button
  //         className="link mx-auto block"
  //         onClick={() => navigate(APP_URLS.DASHBOARD)}
  //       >
  //         I'm not ready
  //       </button>
  //     </>
  //   );
  // }

  // return <Loading padding />;
}
