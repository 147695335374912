import { ProductCTAIcon } from "components/EquityCheck/Actions/components/CTAIcon";

export default function ActionCTATitle({
  title,
  children,
}: {
  title: string;
  children: JSX.Element;
}) {
  return (
    <div className="flex items-center">
      <ProductCTAIcon>{children}</ProductCTAIcon>
      <h3 className="-ml-5 h-fit rounded-lg bg-brand-green-dark px-3 py-2 pl-10 pr-5 text-sm font-semibold uppercase text-white">
        {title}
      </h3>
    </div>
  );
}
