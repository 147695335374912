import FormInputError from "components/Form/Input/Error";
import FormInputLabel from "components/Form/Input/Label";
import { FormInputProps } from "components/Form/Input/types";
import { DEFAULT_COUNTRY, DEFAULT_CURRENCY } from "country-config";
import { useEffect, useState } from "react";
import { asCurrency, inputToFloat } from "utils/currency";
import { classNames } from "utils/helpers";

type FormInputCurrencyProps = FormInputProps & {
  currency?: string;
  countryCode?: string;
  allowZero?: boolean;
};

export default function FormInputCurrency({
  id,
  label,
  error,
  register,
  defaultVal = "",
  disabled = false,
  helperText = "",
  currency = DEFAULT_CURRENCY,
  countryCode = DEFAULT_COUNTRY,
  allowZero = false,
  asText = false,
}: FormInputCurrencyProps) {
  const [inputValue, setInputValue] = useState("");

  let inputStyles =
    "block w-full appearance-none rounded-none rounded-r-md border border-gray-300 px-3 py-2 text-base placeholder-gray-400 focus:border-brand-orange-medium focus:outline-none focus:ring-brand-orange-medium";

  useEffect(() => {
    if (defaultVal) {
      setInputValue(
        asCurrency({
          value: inputToFloat(defaultVal),
          countryCode: countryCode,
          allowZero: allowZero,
        })
      );
    }
  }, [defaultVal, countryCode, allowZero]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    setInputValue(
      asCurrency({
        value: inputToFloat(value),
        countryCode: countryCode,
        allowZero: allowZero,
      })
    );
  };

  const inputProps =
    id && register
      ? {
          ...register(id, {
            required: true,
            onChange: handleOnChange,
          }),
        }
      : {};

  if (asText) {
    disabled = true;
    inputStyles = `${inputStyles} disabled:border-none disabled:bg-white disabled:p-0`;
  }

  return (
    <div>
      <FormInputLabel id={id} label={label} />
      <div className="mt-1 sm:col-span-2">
        {helperText && (
          <label className="mb-1 block text-base text-brand-copy-lighter">
            {helperText}
          </label>
        )}
        <div className={`flex rounded-md ${asText && "-mt-1"}`}>
          <span
            className={classNames(
              asText
                ? "pl-0 pr-1"
                : "border border-r-0 border-gray-300 bg-gray-50 text-gray-500",
              "inline-flex items-center rounded-l-md px-3 text-base"
            )}
          >
            {currency}
          </span>
          <input
            type="text"
            id={id}
            name={id}
            required
            className={inputStyles}
            value={inputValue}
            disabled={disabled}
            inputMode="numeric"
            pattern="[0-9]*"
            placeholder={asText ? "-" : ""}
            {...inputProps}
          />
        </div>
        <FormInputError error={error} />
      </div>
    </div>
  );
}
