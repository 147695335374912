import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";

import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { MUTATION_CREATE_EC_PRODUCT_REMORTGAGE } from "api/mutations/products";
import Loading from "components/Loading";
import { useNavigate } from "react-router-dom";
import { getRootChildRoute } from "routes/utils";
import { APP_URLS } from "settings";
import {
  DEFAULT_PRODUCT_PROGRESS,
  PRODUCT_STEPS,
  useProductsState,
} from "store/Products";
import { useUserStore } from "store/User";

export default function StartProductFromDocs({
  handleError,
}: {
  handleError: Function;
}) {
  const navigate = useNavigate();

  const [loadingDocs, setLoadingDocs] = useState<boolean>(true);
  const [productInstanceCreated, setProductInstanceCreated] =
    useState<boolean>(false);

  const equityCheck = useUserStore((state) => state.equityCheck);
  const setRemortgageProgress = useProductsState(
    (state) => state.setRemortgageProgress
  );

  const [
    createRemortgageProduct,
    { data: mutationData, error: mutationError },
  ] = useMutation(MUTATION_CREATE_EC_PRODUCT_REMORTGAGE);

  useEffect(() => {
    if (equityCheck && !productInstanceCreated) {
      setProductInstanceCreated(true);

      createRemortgageProduct({
        variables: {
          input: {
            id: equityCheck?.id,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equityCheck]);

  useEffect(() => {
    if (mutationData?.response) {
      if (!mutationData.response?.ok) {
        handleError(true);
        return;
      }

      const documentsFetched = mutationData.response?.product?.documents;

      if (!documentsFetched) {
        handleError(true);
        return;
      }

      // Zero docs: handle this with a better error nessage
      if (Object.values(documentsFetched).every((v) => v === false)) {
        handleError(true);
        return;
      }

      const { __typename, ...docTypes } = documentsFetched;

      setRemortgageProgress(
        {
          ...DEFAULT_PRODUCT_PROGRESS,
          ...{
            id: mutationData.response?.product?.id,
            step: PRODUCT_STEPS.REMORTGAGE.DOCS,
            documents: docTypes,
            canAutoRemortgage:
              mutationData.response?.product?.canAutoRemortgage,
          },
        },
        true
      );

      setTimeout(() => setLoadingDocs(false), 500);
      setTimeout(
        () =>
          navigate(
            getRootChildRoute(
              APP_URLS.PRODUCT_PAGES.REMORTGAGE,
              PRODUCT_STEPS.REMORTGAGE.DOCS
            )
          ),
        1000
      );
    }
  }, [mutationData, handleError, setRemortgageProgress, navigate]);

  useEffect(() => {
    if (mutationError) {
      handleError(true);
    }
  }, [mutationError, handleError]);

  return (
    <div className="text-center">
      {loadingDocs ? (
        <>
          <Loading />
          <p className="mt-2">Searching for documents...</p>
        </>
      ) : (
        <>
          <CheckCircleIcon className="bg-green mx-auto h-8 w-8" />
          <p className="mt-2">Success!</p>
        </>
      )}
    </div>
  );
}
