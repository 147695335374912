import { ROOT_HOST } from "settings";

/**
 * Fetch a Cookie value by name
 * @param name
 */
export const getCookie = (name: string) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

/**
 * Delete a cookie by name
 * @param name
 */
export const deleteCookie = (name: string) => {
  if (getCookie(name)) {
    document.cookie =
      name +
      "=null;path=/;domain=" +
      ROOT_HOST +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
};

/**
 * Get bool as Yes/No
 * @param value
 * @returns string Yes/No
 */
export const renderYesNo = (value: any) => {
  if (value) {
    return "Yes";
  }
  return "No";
};

/**
 * Merge a set of class names
 * @param classes
 * @returns string
 */
export const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(" ");
};

/**
 * Async timeout
 * @param timeout
 * @returns
 */
export const wait = (timeout: number) =>
  new Promise((r) => setTimeout(r, timeout));

/**
 * Creates a debounced function that delays invoking the provided
 * function until at least ms milliseconds have elapsed since
 * the last time it was invoked.
 * @param fn Function
 * @param ms timeout
 * @returns
 */
export const debounce = (fn: Function, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

/**
 * Check if a given object is empty
 * @param obj
 * @returns bool
 */
export const isEmptyObject = (obj: Object) =>
  obj && Object.keys(obj).length === 0;

/**
 * Get ordinal suffix for given day number of a month
 * @returns
 */
export const dayOrdinal = (dayNum: number) =>
  dayNum > 0
    ? ["th", "st", "nd", "rd"][
        (dayNum > 3 && dayNum < 21) || dayNum % 10 > 3 ? 0 : dayNum % 10
      ]
    : "";

/**
 * Cast string to Title Case
 */
export const toTitleCase = (stringToCast: string) =>
  stringToCast.replace(/\w\S*/g, (txt: string) => {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
