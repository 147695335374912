import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { useMutation } from "@apollo/client";
import { trackEvent, trackPage } from "analytics";
import { MUTATION_UPDATE_EC_PRODUCT_REMORTGAGE } from "api/mutations/products";
import CTAButton from "components/CTA/Button";
import FormErrors from "components/Form/Errors";
import { validateMin1File } from "components/Form/Input/FileButton";
import FormInputPassword from "components/Form/Input/Password";
import { getRootChildRoute } from "routes/utils";
import { APP_URLS } from "settings";
import { PRODUCT_STEPS, useProductsState } from "store/Products";
import ManualDocUploadAction from "../components/ManualDocUploadAction";
import ProductHeader from "../components/ProductHeader";

type FormInputs = {
  filePayslips: FileList | null;
  fileBankStatements: FileList | null;
  payslipsPassword: string;
};

const schema = yup.object().shape({
  fileBankStatements: validateMin1File("Bank Statements", 3),
  filePayslips: validateMin1File("Payslips", 3),
  payslipsPassword: yup.string().optional().nullable(),
});

export default function Submitted() {
  const navigate = useNavigate();

  const [submitError, setSubmitError] = useState<string | null>(null);
  const [docsUploaded, setDocsUploaded] = useState<boolean>(false);

  const { productRemortgage, setRemortgageProgress } = useProductsState(
    (state) => state
  );

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      filePayslips: null,
      fileBankStatements: null,
      payslipsPassword: "",
    },
  });

  const [
    uploadApplicationDocs,
    { data: mutationData, error: mutationError, loading: mutationLoading },
  ] = useMutation(MUTATION_UPDATE_EC_PRODUCT_REMORTGAGE);

  const uploadedPayslips = watch("filePayslips");
  const uploadedBankStatements = watch("fileBankStatements");

  const formErrors =
    mutationError?.message ||
    submitError ||
    Array.from(Object.values(errors), (error) => error.message);

  useEffect(() => {
    trackPage("Submitted");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!productRemortgage?.applicationData) {
      navigate(
        getRootChildRoute(
          APP_URLS.PRODUCT_PAGES.REMORTGAGE,
          PRODUCT_STEPS.REMORTGAGE.DEALS
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productRemortgage]);

  useEffect(() => {
    if (mutationData?.response) {
      const responseOk = mutationData.response?.ok;
      const docsUploaded = mutationData.response?.product?.documents;

      if (!responseOk || !docsUploaded) {
        setSubmitError(
          "Sorry, there has been an error submitting your documents. Please try again or contact our Customer Success team."
        );
        return;
      }

      const missingDocs = Object.values(docsUploaded).some(
        (value) => value === false
      );

      if (missingDocs) {
        setSubmitError(
          "Please check you have uploaded all required documents."
        );
        return;
      }

      setDocsUploaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationData]);

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    trackEvent("Submit supporting documents");

    uploadApplicationDocs({
      variables: {
        input: {
          id: productRemortgage?.id,
          supportingDocuments: {
            documents: {
              payslips: data.filePayslips,
              bankStatements: data.fileBankStatements,
            },
            payslipsPassword: data.payslipsPassword,
          },
        },
      },
    });
  };

  const handleBackToDashboard = () => {
    setRemortgageProgress({}, true);
    return navigate(APP_URLS.DASHBOARD);
  };

  return (
    <>
      <ProductHeader title="Application submitted!" />

      <div className="mx-4 text-center text-lg">
        <p>
          You're application is now with{" "}
          {productRemortgage?.selectedMortgage?.lender}.
        </p>
        <p>We'll be in touch with an update soon. </p>
      </div>

      <div className="mx-4 rounded-lg bg-gray-100 p-6 md:mx-6">
        {docsUploaded ? (
          <div className="text-center">
            <CheckCircleIcon className="bg-green mx-auto h-8 w-8" />
            <p className="mx-4 mb-4 text-center">
              Thank you, documents uploaded!
            </p>
            <CTAButton
              label="View my Dashboard"
              onClick={handleBackToDashboard}
            />
          </div>
        ) : (
          <>
            <p className="text-center text-lg">
              {productRemortgage?.selectedMortgage?.lender} will also request
              your last 3 months of Bank Statements and Payslips.
            </p>
            <p className="mb-4 text-center text-lg">
              Please upload them below so we can pass them on as part of your
              application.
            </p>

            <form
              className="mx-auto flex w-full max-w-sm flex-col space-y-6 px-4 sm:px-0"
              onSubmit={handleSubmit(onSubmit)}
              encType="multipart/form-data"
              noValidate
            >
              <ManualDocUploadAction
                docID="fileBankStatements"
                docType="Bank Statements"
                helper="Your latest 3 Bank Statements in PDF format"
                formats=".pdf"
                requiredCount={6}
                uploadedDoc={uploadedBankStatements}
                register={register}
                setValue={setValue}
              />
              <ManualDocUploadAction
                docID="filePayslips"
                docType="Payslips"
                helper="Your latest 3 Payslips in PDF format"
                formats=".pdf"
                requiredCount={6}
                uploadedDoc={uploadedPayslips}
                register={register}
                setValue={setValue}
              />
              <FormInputPassword
                id="payslipsPassword"
                label="Payslips password"
                helperText="If your Payslips are password protected, please input the password used to access them. We'll only use this to help us get the data needed for your Remortgage application"
                validate={false}
                register={register}
              />

              {formErrors && <FormErrors errors={formErrors} />}

              <div>
                <CTAButton
                  full
                  type="submit"
                  label="Upload >"
                  loading={mutationLoading}
                />
              </div>
            </form>
          </>
        )}
      </div>
    </>
  );
}
