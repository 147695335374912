import {
  COUNTRIES_CONFIG,
  COUNTRY_CODES,
  COUNTRY_GREAT_BRITAIN,
  DEFAULT_COUNTRY,
} from "country-config";
import { CHECK_STATUS } from "pages/Dashboard/components/EquityCheck/consts";
import { UserState } from "store/User/types";
import create from "zustand";

export const useUserStore = create<UserState>()((set, get) => ({
  appointmentTrigger: false,
  appointments: null,
  equityCheck: null,
  equityCheckIsSuccess: false,
  equityLoanEligible: false,
  eligibleForCall: false,
  eligibleForLead: false,
  propertyAddress: null,
  userLinkedData: null,
  user: null,
  userCountry: COUNTRIES_CONFIG[DEFAULT_COUNTRY],

  setAppointments: (appointments) => set(() => ({ appointments })),

  setAppointmentTrigger: (trigger = true) =>
    set(() => ({ appointmentTrigger: trigger })),

  setPropertyAddress: (address) => set(() => ({ propertyAddress: address })),

  setEquityCheckIsSuccess: (equityCheck) =>
    set(() => ({
      equityCheckIsSuccess:
        equityCheck?.live && equityCheck?.checkStatus === CHECK_STATUS.SUCCESS,
    })),

  setEquityLoanEligible: (equityCheck) =>
    set(() => ({
      equityLoanEligible: [
        equityCheck?.productBuyProperty,
        equityCheck?.productDebtConsolidation,
        equityCheck?.productEquityLoan,
        equityCheck?.productRemortgage,
        equityCheck?.productEquityRelease,
        equityCheck?.productMortgageProtection,
      ].some((val) => val),
    })),

  setEligibleForCall: (user) =>
    set((state) => ({
      eligibleForCall:
        state.userCountry.CAN_BOOK_CALLS &&
        user?.detail?.age &&
        user?.detail?.age < 60,
    })),

  setEligibleForLead: (equityCheck, user = null) =>
    set((state) => {
      const _user = user || state.user;

      // Country required to support calls
      if (!state.userCountry.CAN_BOOK_CALLS) {
        return { eligibleForLead: false };
      }

      // Equity Check must be success
      if (!state.equityCheckIsSuccess) {
        return { eligibleForLead: false };
      }

      // We dont need income for equity release products only,
      // but they cant have credit issues
      if (
        !_user?.detail?.householdIncome &&
        equityCheck?.productEquityRelease &&
        !equityCheck?.live?.hasCreditIssues
      ) {
        return { eligibleForLead: true };
      }

      // Core eligibility: Has some loan products, income input, no credit issues
      let isEligible =
        state.equityLoanEligible &&
        _user?.detail?.householdIncome &&
        !equityCheck?.live?.hasCreditIssues;

      return { eligibleForLead: !!isEligible };
    }),

  setEquityCheck: (equityCheck, user = null) =>
    set(() => {
      get().setEquityCheckIsSuccess(equityCheck);
      get().setEquityLoanEligible(equityCheck);
      get().setEligibleForLead(equityCheck, user);
      return { equityCheck };
    }),

  setUserCountry: (countryCode) =>
    set(() => {
      // Always set country to GB if is sub country type, eg England / GB-ENG
      if (countryCode.toLocaleLowerCase().includes("gb-")) {
        countryCode = COUNTRY_GREAT_BRITAIN;
      }
      if (COUNTRY_CODES.includes(countryCode)) {
        return { userCountry: COUNTRIES_CONFIG[countryCode] };
      }
      return { userCountry: COUNTRIES_CONFIG[DEFAULT_COUNTRY] };
    }),

  updateUserPhoneNumber: (phoneNumber: string) =>
    set((state) =>
      state.user
        ? {
            user: {
              ...state.user,
              detail: {
                ...state.user.detail,
                phoneNumber: phoneNumber,
              },
            },
          }
        : { user: state.user }
    ),

  updateCallWindowOpen: (windowOpen: boolean) =>
    set((state) =>
      state.user
        ? {
            user: {
              ...state.user,
              ...{
                callWindowOpen: windowOpen,
              },
            },
          }
        : { user: state.user }
    ),

  setUserLinkedData: (data) =>
    set(() => ({
      userLinkedData: data,
    })),

  setUser: (user) =>
    set(() => {
      const thisUser = JSON.parse(JSON.stringify(user));

      // Set EquityCheck data to store if exists for this User
      if (thisUser?.equityCheck) {
        get().setUserCountry(thisUser.equityCheck.addressCountry);
        get().setPropertyAddress({
          shortAddress: thisUser.equityCheck.shortAddress,
          townOrCity: thisUser.equityCheck.addressTownOrCity,
          postcode: thisUser.equityCheck.addressPostcode,
        });
        get().setEquityCheck(thisUser.equityCheck, thisUser);
        delete thisUser.equityCheck;
      }

      get().setEligibleForCall(thisUser);

      return {
        user: thisUser,
      };
    }),
}));
