import create from "zustand";

import { ProductRemortgageType, ProductsState } from "store/Products/types";

export const PRODUCT_STEPS = {
  START: "START",
  REMORTGAGE: {
    UPLOAD_DOCS: "UPLOAD_DOCS",
    DOCS: "DOCS",
    DEALS: "DEALS",
    ILLUSTRATION: "ILLUSTRATION",
    REVIEW: "REVIEW",
    SUBMITTED: "SUBMITTED",
    TALK_TO_US: "TALK_TO_US",
  },
};

export const DEFAULT_PRODUCT_PROGRESS = {
  step: PRODUCT_STEPS.START,
};

export const useProductsState = create<ProductsState>()((set) => ({
  productRemortgage: null,
  productApplicationStep: PRODUCT_STEPS.START,
  productApplicationStepsComplete: [],

  setProductApplicationStep: (step) =>
    set((state: any) => {
      state.productApplicationStepsComplete.push(step);
      return {
        productApplicationStepsComplete: state.productApplicationStepsComplete,
        productApplicationStep: step,
      };
    }),

  setRemortgageProgress: (
    progressData: ProductRemortgageType,
    reset: boolean = false
  ) =>
    set((state: any) => {
      if (reset) {
        if (progressData) {
          return {
            productRemortgage: progressData,
          };
        } else {
          return {
            productApplicationStepsComplete: [],
            productApplicationStep: PRODUCT_STEPS.START,
            productRemortgage: {},
          };
        }
      } else {
        return {
          productRemortgage: {
            ...(state.productRemortgage || {}),
            ...progressData,
          },
        };
      }
    }),
}));
