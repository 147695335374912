import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { trackPage } from "analytics";
import ActionCTA from "components/ActionCTA";
import CTAButton from "components/CTA/Button";
import Loading from "components/Loading";
import { getRootChildRoute } from "routes/utils";
import { APP_URLS } from "settings";
import { PRODUCT_STEPS, useProductsState } from "store/Products";
import { CommonObjectType } from "ts/types";
import ManualDocUpload from "../components/ManualDocUpload";
import ProductHeader from "../components/ProductHeader";

type DocsState = {
  verified: Array<string>;
  missing: Array<string>;
};

const DEFAULT_DOC_TYPE = {
  icon: <CheckCircleIcon className="h-8 w-auto text-brand-green-medium" />,
};

const DOC_TYPES: CommonObjectType = {
  mortgageOffer: {
    ...DEFAULT_DOC_TYPE,
    title: "Previous Mortgage Offer",
  },
  payslips: {
    ...DEFAULT_DOC_TYPE,
    title: "Last 3 months Payslips",
  },
  passport: {
    ...DEFAULT_DOC_TYPE,
    title: "Passport",
  },
  bankStatements: {
    ...DEFAULT_DOC_TYPE,
    title: "Latest 3 months Bank Statements",
  },
};

export default function DocsResult() {
  const navigate = useNavigate();

  const [docsState, setDocsState] = useState<DocsState>({
    verified: [],
    missing: [],
  });

  const productRemortgage = useProductsState(
    (state) => state.productRemortgage
  );
  const setRemortgageProgress = useProductsState(
    (state) => state.setRemortgageProgress
  );

  useEffect(() => {
    trackPage("Documents result");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!productRemortgage?.documents) {
      return navigate(APP_URLS.DASHBOARD);
    }

    const _verifiedDocs = [];
    const _missingDocs = [];

    for (const [key, value] of Object.entries(productRemortgage.documents)) {
      if (value) {
        _verifiedDocs.push(key);
      } else {
        _missingDocs.push(key);
      }
    }

    setDocsState({
      verified: _verifiedDocs,
      missing: _missingDocs,
    });
  }, [productRemortgage, navigate]);

  const nextStep = () => {
    setRemortgageProgress({
      ...productRemortgage,
      ...{
        step: PRODUCT_STEPS.REMORTGAGE.DEALS,
        hasRequiredDocs: true,
      },
    });
    navigate(
      getRootChildRoute(
        APP_URLS.PRODUCT_PAGES.REMORTGAGE,
        PRODUCT_STEPS.REMORTGAGE.DEALS
      )
    );
  };

  if (productRemortgage) {
    return (
      <>
        <ProductHeader title="Thanks for connecting your inbox.">
          <p className="mb-4 mt-1 text-2xl">
            {docsState.missing.length
              ? "We were not able to find all the required documents:"
              : "We successfully found the following required documents:"}
          </p>
        </ProductHeader>

        <div className="mx-0 rounded-lg bg-gray-100 py-6 md:mx-6">
          <div className="mx-auto flex w-full max-w-sm flex-col space-y-6 px-4 sm:px-0">
            <ActionCTA name="RemortgageStart" bookACall={false} small>
              <>
                <ul className="space-y-2">
                  {docsState.verified.length > 0 &&
                    docsState.verified.map((docType: string) => (
                      <li key={docType}>
                        <div className="relative flex items-center space-x-2">
                          <div className="flex-shrink-0">
                            {DOC_TYPES[docType].icon}
                          </div>
                          <div className="min-w-0 flex-1">
                            <p>{DOC_TYPES[docType].title}</p>
                          </div>
                        </div>
                      </li>
                    ))}

                  {docsState.missing.length > 0 &&
                    docsState.missing.map((docType: string) => (
                      <li key={docType}>
                        <div className="relative flex items-center space-x-2">
                          <div className="flex-shrink-0">
                            <XCircleIcon className="h-8 w-auto text-red-800" />
                          </div>
                          <div className="min-w-0 flex-1">
                            <p>{DOC_TYPES[docType].title}</p>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </>
            </ActionCTA>

            <div className="text-center">
              {docsState.missing.length > 0 ? (
                <>
                  <p className="mb-4">
                    Please upload the documents we were not able to find, so you
                    can continue your Remortgage.
                  </p>
                  <ManualDocUpload />
                </>
              ) : (
                <>
                  <p className="mb-4">
                    Please now approve the found documents and our AI will pull
                    the required data from each so you can continue your
                    Remortgage.
                  </p>
                  <CTAButton label="Approve & Continue >" onClick={nextStep} />
                </>
              )}
            </div>
          </div>
        </div>

        {/* <button
          className="link mx-auto block"
          onClick={() => navigate(APP_URLS.DASHBOARD)}
        >
          I'm not ready
        </button> */}
      </>
    );
  }

  return <Loading padding />;
}
