import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

import { getRootChildRoute } from "routes/utils";
import { APP_URLS } from "settings";
import { PRODUCT_STEPS } from "store/Products";

export default function ManualDocUpload() {
  const navigate = useNavigate();

  const naviateToUploadDocs = () =>
    navigate(
      getRootChildRoute(
        APP_URLS.PRODUCT_PAGES.REMORTGAGE,
        PRODUCT_STEPS.REMORTGAGE.UPLOAD_DOCS
      )
    );

  return (
    <button
      type="button"
      onClick={naviateToUploadDocs}
      className="inline-flex cursor-pointer items-center justify-center space-x-2 rounded-md border bg-white px-4 py-1.5 text-base shadow-md hover:bg-gray-50"
    >
      <ArrowUpTrayIcon className="h-6 w-auto" />
      <span>Manually upload documents</span>
    </button>
  );
}
