import { useEffect } from "react";
import { useNavigate } from "react-router";

import { trackPage } from "analytics";
import { APP_URLS } from "settings";
import { useProductsState } from "store/Products";
import ProductHeader from "../components/ProductHeader";

export default function TalkToUs() {
  const navigate = useNavigate();

  const productRemortgage = useProductsState(
    (state) => state.productRemortgage
  );

  useEffect(() => {
    trackPage("Talk to Us");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!productRemortgage?.id) {
      return navigate(APP_URLS.DASHBOARD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productRemortgage]);

  return (
    <>
      <ProductHeader title="Talk to us" />
      <div>
        <p className="text-md mx-4 mt-4 text-center">
          Sorry, we're unable to take your application forward at this point.
        </p>
        <p className="text-md mx-4 mt-2 text-center">
          Please contact our Customer Service team to discuss your options.
        </p>
      </div>
    </>
  );
}
