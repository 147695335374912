import { useEffect } from "react";

import { trackEvent, trackPage } from "analytics";
import Loading from "components/Loading";
import { useNavigate } from "react-router";
import { getRootChildRoute } from "routes/utils";
import { APP_URLS } from "settings";
import { PRODUCT_STEPS, useProductsState } from "store/Products";
import { DealsListing } from "../components/DealsListing";
import { ManualQuestions } from "../components/ManualQuestions";

export default function RemortgageDeals() {
  const navigate = useNavigate();

  const productRemortgage = useProductsState(
    (state) => state.productRemortgage
  );

  useEffect(() => {
    trackPage("Deals");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!productRemortgage?.id) {
      return navigate(APP_URLS.DASHBOARD);
    }

    // Redirect to talk to us page if customer does not meet criteria for auto remortgage
    if (!productRemortgage?.canAutoRemortgage) {
      trackEvent("Customer can not auto-remortgage");

      return navigate(
        getRootChildRoute(
          APP_URLS.PRODUCT_PAGES.REMORTGAGE,
          PRODUCT_STEPS.REMORTGAGE.TALK_TO_US
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productRemortgage]);

  if (!productRemortgage?.id) {
    return <Loading padding />;
  }

  if (productRemortgage?.manualData) {
    return <DealsListing productRemortgage={productRemortgage} />;
  }

  return <ManualQuestions productRemortgage={productRemortgage} />;
}
