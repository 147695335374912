import { IS_TEST_ENV } from "settings";

function LogoWhiteAhauz() {
  return (
    <svg
      width="134"
      height="35"
      viewBox="0 0 134 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="h-8 w-auto"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.9283 14.2181L44.0357 34.6787H48.7133L50.1162 30.8487V30.9072H58.1539V30.8487L59.6152 34.6787H64.3502L56.3417 14.2181H51.9283ZM54.1485 19.0115L56.8678 27.1953H51.3438L54.1485 19.0115Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.7814 13.5458H70.1364V21.7296C71.3932 19.9759 72.9715 19.3621 74.7836 19.3621C78.9924 19.3621 80.1334 22.7244 80.1334 26.5229V34.6786H75.7785V26.6117C75.7785 24.5354 75.0174 22.9583 73.0007 22.9583C70.984 22.9583 70.1364 24.5658 70.1364 26.6398V34.6786H65.7814V13.5458Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.4502 31.463C92.5827 31.463 94.3656 30.0893 94.3656 27.3711C94.3656 24.5945 92.7007 22.8992 90.3333 22.8992C87.9366 22.8992 86.591 24.8283 86.591 27.1373C86.591 29.534 88.0524 31.463 90.4502 31.463ZM98.6047 19.6842V34.6781H94.2498V32.486C93.1684 34.035 91.7362 34.9996 89.543 34.9996C84.9846 34.9996 82.236 31.4922 82.236 27.108C82.236 22.8992 84.7789 19.3627 89.4553 19.3627C91.4732 19.3627 93.2268 20.3564 94.2498 21.7301V19.6842H98.6047Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.937 34.6785H111.582V32.6325C110.325 34.3862 108.747 35 106.933 35C102.725 35 101.585 31.6388 101.585 27.8392V19.6846H105.94V27.7515C105.94 29.8267 106.7 31.405 108.717 31.405C110.734 31.405 111.582 29.7974 111.582 27.7223V19.6846H115.937V34.6785Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M133.415 34.6785H118.186V32.1357L126.604 23.1043H118.595V19.6846H132.918V22.2567L124.53 31.2004H133.415V34.6785Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3655 0L0 14.2396H6.75163V34.6788L21.3655 3.78163L35.9795 34.6788V14.2396H42.73L21.3655 0Z"
        fill="white"
      />
    </svg>
  );
}

function LogoWhiteVeilo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="1500"
      height="443"
      viewBox="0 0 1500 443"
      className="h-9 w-auto"
    >
      <g transform="matrix(1,0,0,1,-0.9090909090909918,-0.5253185323556977)">
        <svg
          viewBox="0 0 396 117"
          data-background-color="#44b792"
          preserveAspectRatio="xMidYMid meet"
          height="443"
          width="1500"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="tight-bounds"
            transform="matrix(1,0,0,1,0.2400000000000091,0.13874101193141541)"
          >
            <svg
              viewBox="0 0 395.52 116.72251797613717"
              height="116.72251797613717"
              width="395.52"
            >
              <g>
                <svg
                  viewBox="0 0 395.52 116.72251797613717"
                  height="116.72251797613717"
                  width="395.52"
                >
                  <g>
                    <svg
                      viewBox="0 0 395.52 116.72251797613717"
                      height="116.72251797613717"
                      width="395.52"
                    >
                      <g>
                        <svg
                          viewBox="0 0 395.52 116.72251797613717"
                          height="116.72251797613717"
                          width="395.52"
                        >
                          <g>
                            <svg
                              viewBox="0 0 395.52 116.72251797613717"
                              height="116.72251797613717"
                              width="395.52"
                            >
                              <g id="textblocktransform">
                                <svg
                                  viewBox="0 0 395.52 116.72251797613717"
                                  height="116.72251797613717"
                                  width="395.52"
                                  id="textblock"
                                >
                                  <g>
                                    <svg
                                      viewBox="0 0 395.52 116.72251797613717"
                                      height="116.72251797613717"
                                      width="395.52"
                                    >
                                      <g transform="matrix(1,0,0,1,0,0)">
                                        <svg
                                          width="395.52"
                                          viewBox="-1.0699999999999998 -40.52 149.29999999999998 44.760000000000005"
                                          height="116.72251797613717"
                                          data-palette-color="#ffffff"
                                        >
                                          <path
                                            d="M14.11 0.2L2.93-35.99 9.62-35.99 17.19-7.76 17.19 0.2 14.11 0.2ZM19.19-9.33L26.81-35.99 33.45-35.99 21.73 0.2 19.19 0.2 19.19-9.33ZM40.92 0L40.92-35.99 46.87-35.99 46.87 0 40.92 0ZM61.91-20.21L61.91-16.21 48.87-16.21 48.87-20.21 61.91-20.21ZM48.87 0L48.87-4.44 63.43-4.44 63.43 0 48.87 0ZM48.87-35.99L63.43-35.99 63.43-31.54 48.87-31.54 48.87-35.99ZM77.49-35.99L77.49 0 71.53 0 71.53-35.99 77.49-35.99ZM86.52-35.99L92.47-35.99 92.47-4.44 109.03-4.44 109.03 0 86.52 0 86.52-35.99ZM114.25-18.16L114.25-18.16Q114.25-22.51 115.3-25.85 116.35-29.2 118.23-31.52 120.11-33.84 122.67-35.08 125.23-36.33 128.26-36.52L128.26-36.52 128.26-31.64Q126.6-31.45 125.14-30.62 123.67-29.79 122.6-28.17 121.52-26.56 120.89-24.07 120.25-21.58 120.2-18.16L120.2-18.16Q120.25-14.7 120.89-12.23 121.52-9.77 122.62-8.11 123.72-6.45 125.19-5.59 126.65-4.74 128.26-4.59L128.26-4.59 128.26 0.24Q125.28 0.05 122.7-1.2 120.11-2.44 118.23-4.76 116.35-7.08 115.3-10.45 114.25-13.82 114.25-18.16ZM130.21-31.69L130.21-36.52Q133.19-36.38 135.76-35.11 138.32-33.84 140.2-31.52 142.08-29.2 143.15-25.85 144.23-22.51 144.23-18.16L144.23-18.16Q144.23-13.82 143.15-10.45 142.08-7.08 140.2-4.76 138.32-2.44 135.78-1.2 133.24 0.05 130.21 0.24L130.21 0.24 130.21-4.59Q131.87-4.79 133.34-5.64 134.8-6.49 135.88-8.13 136.95-9.77 137.59-12.23 138.22-14.7 138.27-18.16L138.27-18.16Q138.22-21.63 137.59-24.1 136.95-26.56 135.85-28.2 134.76-29.83 133.29-30.69 131.83-31.54 130.21-31.69L130.21-31.69Z"
                                            opacity="1"
                                            transform="matrix(1,0,0,1,0,0)"
                                            fill="#ffffff"
                                            data-fill-palette-color="primary"
                                            id="text-0"
                                          ></path>
                                        </svg>
                                      </g>
                                    </svg>
                                  </g>
                                </svg>
                              </g>
                            </svg>
                          </g>
                          <g></g>
                        </svg>
                      </g>
                    </svg>
                  </g>
                </svg>
              </g>
              <defs></defs>
            </svg>
            <rect
              width="395.52"
              height="116.72251797613717"
              fill="none"
              stroke="none"
              visibility="hidden"
            ></rect>
          </g>
        </svg>
      </g>
    </svg>
  );
}

export default function LogoWhite() {
  if (IS_TEST_ENV) {
    return <LogoWhiteVeilo />;
  }
  return <LogoWhiteAhauz />;
}
