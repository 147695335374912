import CTAButton from "components/CTA/Button";
import FormErrors from "components/Form/Errors";
import FormInputCurrency from "components/Form/Input/Currency";
import FormInputPercent from "components/Form/Input/Percent";
import FormInputRadioGroup from "components/Form/Input/RadioGroup";
import FormInputText from "components/Form/Input/Text";
import { Fragment, ReactNode, useState } from "react";
import { FieldError, get, useFieldArray, useForm } from "react-hook-form";
import { useProductsState } from "store/Products";
import {
  ProductRemortgageApplicationFieldObjectType,
  ProductRemortgageApplicationFieldsType,
} from "store/Products/types";
import { CommonObjectType } from "ts/types";
import { isEmptyObject } from "utils/helpers";
import {
  APPLICATION_DATA_MAP,
  getDefaultsFromFieldsetFields,
  transformApplicationDataForValidation,
  transformApplicationFromStore,
} from "../application";

export default function ReviewEditForm({ onSubmit }: { onSubmit: Function }) {
  const [editMode, setEditMode] = useState<boolean>(true);

  const { productRemortgage } = useProductsState((state) => state);

  const applicationData = transformApplicationFromStore(
    productRemortgage?.applicationData
  );

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: transformApplicationDataForValidation(applicationData),
    defaultValues: applicationData,
  });

  const {
    fields: ahFields,
    append: ahFieldsAppend,
    remove: ahFieldsRemove,
  } = useFieldArray({
    control,
    name: "address_history",
  });

  const {
    fields: ccFields,
    append: ccFieldsAppend,
    remove: ccFieldsRemove,
  } = useFieldArray({
    control,
    name: "credit_commitments",
  });

  const renderFieldInput = (
    fieldID: string,
    fieldConfig: ProductRemortgageApplicationFieldObjectType,
    fieldDefaultValue: any
  ) => {
    const fieldError = (get(errors, fieldID) as FieldError)?.message;

    let renderAsText = !editMode;

    // If a field is defined as not editable, always render as text
    if ("editable" in fieldConfig && !fieldConfig.editable) {
      renderAsText = true;
    }

    if (fieldConfig?.format) {
      if (fieldConfig.format === "yesno") {
        return (
          <FormInputRadioGroup
            id={fieldID}
            options={["Yes", "No"]}
            error={fieldError}
            control={control}
            asText={renderAsText}
            fiftyfifty
          />
        );
      }
      if (fieldConfig.format === "currency") {
        return (
          <FormInputCurrency
            id={fieldID}
            asText={renderAsText}
            register={register}
            defaultVal={fieldDefaultValue}
            error={fieldError}
          />
        );
      }
      if (fieldConfig.format === "number") {
        return (
          <FormInputText
            id={fieldID}
            type="number"
            register={register}
            asText={renderAsText}
            error={fieldError}
          />
        );
      }
      if (fieldConfig.format === "percent") {
        return (
          <FormInputPercent
            id={fieldID}
            register={register}
            asText={renderAsText}
            error={fieldError}
          />
        );
      }
    }

    return (
      <FormInputText
        id={fieldID}
        register={register}
        asText={renderAsText}
        error={fieldError}
      />
    );
  };

  const renderFields = (
    fieldset: string,
    fields:
      | ProductRemortgageApplicationFieldsType
      | Array<ProductRemortgageApplicationFieldsType>,
    fieldIndex?: string
  ): ReactNode => {
    if (Array.isArray(fields)) {
      let arrayFields: any[] = fields;
      let singularAddType: string = "";
      let arrayFieldAddFunc: Function = () => null;
      let arrayFieldRemoveFunc: Function = () => null;

      if (fieldset === "credit_commitments") {
        arrayFields = ccFields;
        arrayFieldAddFunc = ccFieldsAppend;
        arrayFieldRemoveFunc = ccFieldsRemove;
        singularAddType = "commitment";
      }

      if (fieldset === "address_history") {
        arrayFields = ahFields;
        arrayFieldAddFunc = ahFieldsAppend;
        arrayFieldRemoveFunc = ahFieldsRemove;
        singularAddType = "address";
      }

      return (
        <div className="divide-y">
          {arrayFields.map((aFields, i) => {
            const commitmentNumber = i + 1;
            return (
              <div
                key={aFields.id}
                className="space-y-2 pb-4 pt-4 first:pt-0 last:pb-0"
              >
                <div className="flex items-center justify-between">
                  <h4 className="text-lg font-semibold">{commitmentNumber}</h4>
                  {arrayFields.length > 1 && (
                    <button
                      className="link underline"
                      type="button"
                      onClick={() => arrayFieldRemoveFunc(i)}
                    >
                      Delete
                    </button>
                  )}
                </div>
                {renderFields(fieldset, aFields, i.toString())}
              </div>
            );
          })}
          <button
            className="link w-full pt-2 text-right underline"
            type="button"
            onClick={() =>
              arrayFieldAddFunc(
                getDefaultsFromFieldsetFields(
                  APPLICATION_DATA_MAP[fieldset].fields
                )
              )
            }
          >
            Add new {singularAddType}
          </button>
        </div>
      );
    }

    return Object.entries(fields).map(([fieldKey, fieldDefaultValue]) => {
      const fieldIDIndex = fieldIndex ? `.${fieldIndex}.` : ".";
      const fieldID = `${fieldset}${fieldIDIndex}${fieldKey}`;
      const fieldConfig = APPLICATION_DATA_MAP[fieldset].fields[fieldKey];
      const fieldLabel = fieldConfig?.label || "";

      if (fieldKey === "id") {
        return <Fragment key={fieldID}></Fragment>;
      }

      return (
        <div key={fieldID} className="flex flex-col justify-between">
          {fieldConfig?.subtitle && (
            <span className="font-semibold">{fieldConfig.subtitle}</span>
          )}
          <span className="font-semibold">{fieldLabel}</span>
          {renderFieldInput(fieldID, fieldConfig, fieldDefaultValue)}
        </div>
      );
    });
  };

  const toggleEditMode = () => setEditMode(editMode ? false : true);

  return (
    <form
      className="mx-auto flex max-w-md flex-col justify-center space-y-6"
      onSubmit={handleSubmit((data) => onSubmit(data))}
      noValidate
    >
      {Object.entries(applicationData).map(
        ([fieldset, fields]: [string, CommonObjectType]) =>
          APPLICATION_DATA_MAP?.[fieldset] && (
            <div key={fieldset}>
              <h3 className="text-center text-xl font-semibold">
                {APPLICATION_DATA_MAP[fieldset].fieldset}
              </h3>
              <div className="mt-2 space-y-2 rounded-lg bg-white px-4 py-3 shadow">
                {renderFields(fieldset, fields)}
              </div>
            </div>
          )
      )}

      <div className="flex flex-col justify-between space-y-4">
        {!isEmptyObject(errors) && <FormErrors errors={errors} />}

        <div>
          <CTAButton full type="submit" label="Submit Application >" />
        </div>

        {!editMode && (
          <div>
            <CTAButton
              secondary
              full
              label="Edit answers"
              onClick={toggleEditMode}
            />
          </div>
        )}
      </div>
    </form>
  );
}
