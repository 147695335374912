import Container from "components/Dashboard/Container";
import { StatType } from "pages/Dashboard/components/EquityCheck/types";
import { useUserStore } from "store/User";
import { asCurrency } from "utils/currency";
import { classNames } from "utils/helpers";

export default function EquityStat({
  stat,
  value,
  children,
  renderStat = true,
}: {
  stat: StatType;
  value?: number;
  children?: JSX.Element;
  renderStat?: boolean;
}) {
  const { CURRENCY_SYMBOL, COUNTRY_CODE } = useUserStore(
    (state) => state.userCountry
  );

  const statTitle = stat.getTitle();
  const showValue = value && renderStat;
  const statClass = `stat-${statTitle
    .toLocaleLowerCase()
    .replace(/\s+/g, "-")}`;

  return (
    <div
      className={`${statClass} relative min-w-[65%] flex-shrink-0 snap-center md:min-w-[40%] lg:min-w-[70%]`}
    >
      <Container>
        <div className="h-full p-4">
          {stat.icon}
          <div className="mt-2 text-base font-bold uppercase tracking-wide text-brand-green-dark">
            {statTitle}
          </div>
          <div
            className={classNames(
              showValue ? "" : "leading-none",
              "no-scroll-bar -mb-1 mt-1 overflow-y-hidden overflow-x-scroll text-5xl font-normal md:text-5.5xl",
              "stat"
            )}
          >
            {showValue
              ? `${CURRENCY_SYMBOL}${asCurrency({
                  value: value,
                  countryCode: COUNTRY_CODE,
                })}`
              : "-"}
          </div>
          {children && children}
        </div>
      </Container>
    </div>
  );
}
