import LogoWhite from "components/Image/Logo/White";
import { IS_TEST_ENV } from "settings";
import { useUserStore } from "store/User";

export default function Footer() {
  const {
    SHOW_DISCLAIMER,
    SHOW_CONTACT_LINK,
    SHOW_COOKIE_POLICY_LINK,
    COPY,
    LINKS,
  } = useUserStore((state) => state.userCountry);

  return (
    <footer className="brand-hero">
      <div className="mx-auto px-4 py-8 2xl:container sm:px-6 lg:px-8 lg:py-12">
        <div className="grid grid-cols-1 lg:grid-cols-3">
          <div className="col-span-2 flex flex-col justify-between">
            <a href="/" className="hidden lg:block">
              <LogoWhite />
            </a>

            {IS_TEST_ENV ? (
              <p className="mt-6 text-center text-base text-white lg:text-left">
                {COPY.COPYRIGHT}
              </p>
            ) : (
              <>
                {SHOW_DISCLAIMER && (
                  <p className="mt-10 text-center text-base text-white sm:mt-6 lg:text-left">
                    Ahauz Finance Limited is authorised and regulated by the
                    Financial Conduct Authority (Firm reference number 846554).
                  </p>
                )}

                <p className="mt-6 text-center text-base text-white lg:text-left">
                  {COPY.COPYRIGHT}
                </p>

                {SHOW_DISCLAIMER && (
                  <div className="mt-8 text-center text-white lg:mt-16 lg:text-left">
                    <p className="text-sm">
                      Ahauz is the trading name of Ahauz Finance Limited, a
                      company registered in England and Wales{" "}
                      <br className="block lg:hidden" />
                      (Reference number: 11835452);
                    </p>
                    <p className="mt-1 text-sm">
                      Registered office: 10 Orange St, Haymarket, London, WC2H
                      7DQ.
                    </p>
                  </div>
                )}
              </>
            )}
          </div>

          <div className="order-first col-span-1 flex flex-col justify-between text-center lg:order-1 lg:text-right">
            <a href="/" className="m-auto mb-10 block lg:mb-0 lg:hidden">
              <LogoWhite />
            </a>

            <div>
              <h3 className="text-center text-base font-semibold tracking-wide text-white lg:text-right">
                Useful links
              </h3>
              <nav>
                <ul className="mt-2 space-y-1 lg:mt-3">
                  {SHOW_CONTACT_LINK && (
                    <li>
                      <a
                        href="https://ahauz.com/contact/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="text-white hover:text-brand-orange-medium">
                          Contact
                        </span>
                      </a>
                    </li>
                  )}
                  <li>
                    <a href={LINKS.TERMS} target="_blank" rel="noreferrer">
                      <span className="text-white hover:text-brand-orange-medium">
                        Terms &amp; Conditions
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href={LINKS.PRIVACY} target="_blank" rel="noreferrer">
                      <span className="text-white hover:text-brand-orange-medium">
                        Privacy Policy
                      </span>
                    </a>
                  </li>
                  {SHOW_COOKIE_POLICY_LINK && (
                    <li>
                      <a
                        href="https://ahauz.com/cookie-policy/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="text-white hover:text-brand-orange-medium">
                          Cookie Policy
                        </span>
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>

            <div className="mt-4 space-y-4">
              <a
                href="https://www.facebook.com/ahauzai"
                target="_blank"
                rel="noreferrer"
                className="inline-block pr-4 text-base text-white hover:text-brand-orange-medium"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"
                    fill="currentColor"
                  />
                </svg>
              </a>
              <a
                href="https://www.instagram.com/ahauzteam/"
                target="_blank"
                rel="noreferrer"
                className="inline-block pr-4 text-base text-white hover:text-brand-orange-medium"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
                    fill="currentColor"
                  />
                </svg>
              </a>
              <a
                href="https://twitter.com/ahauz_team"
                target="_blank"
                rel="noreferrer"
                className="inline-block pr-4 text-base text-white hover:text-brand-orange-medium"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0)">
                    <path
                      className="transition-all"
                      d="M7.55016 21.75C16.6045 21.75 21.5583 14.2468 21.5583 7.74192C21.5583 7.53098 21.5536 7.31536 21.5442 7.10442C22.5079 6.40752 23.3395 5.54431 24 4.55536C23.1025 4.95466 22.1496 5.21544 21.1739 5.3288C22.2013 4.71297 22.9705 3.74553 23.3391 2.60583C22.3726 3.17862 21.3156 3.58267 20.2134 3.80067C19.4708 3.01162 18.489 2.48918 17.4197 2.31411C16.3504 2.13905 15.2532 2.32111 14.2977 2.83216C13.3423 3.3432 12.5818 4.15477 12.1338 5.14137C11.6859 6.12798 11.5754 7.23468 11.8195 8.29036C9.86249 8.19215 7.94794 7.68376 6.19998 6.79816C4.45203 5.91255 2.90969 4.6695 1.67297 3.14958C1.0444 4.2333 0.852057 5.51571 1.13503 6.73615C1.418 7.9566 2.15506 9.02351 3.19641 9.72005C2.41463 9.69523 1.64998 9.48474 0.965625 9.10598V9.16692C0.964925 10.3042 1.3581 11.4066 2.07831 12.2868C2.79852 13.167 3.80132 13.7706 4.91625 13.995C4.19206 14.1932 3.43198 14.2221 2.69484 14.0794C3.00945 15.0575 3.62157 15.9129 4.44577 16.5264C5.26997 17.1398 6.26512 17.4807 7.29234 17.5013C5.54842 18.8712 3.39417 19.6142 1.17656 19.6107C0.783287 19.6101 0.390399 19.586 0 19.5385C2.25286 20.9838 4.87353 21.7514 7.55016 21.75Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="24" height="24" fill="currentColor" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/company/ahauz"
                target="_blank"
                rel="noreferrer"
                className="inline-block text-base text-white hover:text-brand-orange-medium"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="transition-all"
                    d="M22.2234 0H1.77187C0.792187 0 0 0.773438 0 1.72969V22.2656C0 23.2219 0.792187 24 1.77187 24H22.2234C23.2031 24 24 23.2219 24 22.2703V1.72969C24 0.773438 23.2031 0 22.2234 0ZM7.12031 20.4516H3.55781V8.99531H7.12031V20.4516ZM5.33906 7.43438C4.19531 7.43438 3.27188 6.51094 3.27188 5.37187C3.27188 4.23281 4.19531 3.30937 5.33906 3.30937C6.47813 3.30937 7.40156 4.23281 7.40156 5.37187C7.40156 6.50625 6.47813 7.43438 5.33906 7.43438ZM20.4516 20.4516H16.8937V14.8828C16.8937 13.5562 16.8703 11.8453 15.0422 11.8453C13.1906 11.8453 12.9094 13.2937 12.9094 14.7891V20.4516H9.35625V8.99531H12.7687V10.5609H12.8156C13.2891 9.66094 14.4516 8.70938 16.1813 8.70938C19.7859 8.70938 20.4516 11.0813 20.4516 14.1656V20.4516Z"
                    fill="currentColor"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
